import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { EyeOff, Eye } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const result = await login(email, password);
      if (result.success) {
        // 登錄成功，localStorage 的設置已經在 AuthContext 中處理
        navigate(`/${result.username}/`);
      } else {
        setError('登入失敗。請檢查您的帳號和密碼。');
      }
    } catch (err) {
      setError('發生錯誤。請稍後再試。');
      console.error('Login error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-start p-5 pb-10 gap-6 w-full min-h-screen bg-gradient-to-b from-[#E9F3D1] to-white">
      <div className="flex items-center gap-2 mt-20 pb-10">
        <img src="/images/stanec-logo.png" alt="STANEC" className="w-full h-full object-cover" />
      </div>
      
      <div className="w-full">
        <h1 className="text-2xl font-black text-[#398378] font-['Noto_Serif_TC']">登入</h1>
        <div className="flex items-center justify-between mt-2">
          <span className="text-sm text-[#001528]">還不是會員？</span>
          <Link to="/register" className="flex items-center text-sm text-[#398378]">
            立即註冊
            <svg className="w-5 h-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </Link>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="w-full space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm text-[#1D443E] mb-2">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-5 py-2.5 bg-white rounded-full shadow-md text-[#222222] placeholder-[#CCD5AE]"
            placeholder="輸入Email"
            required
          />
        </div>
        
        <div>
          <label htmlFor="password" className="block text-sm text-[#1D443E] mb-2">密碼</label>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-5 py-2.5 bg-white rounded-full shadow-md text-[#222222] placeholder-[#CCD5AE]"
              placeholder="輸入密碼"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              {showPassword ? <EyeOff size={24} className="text-[#AAB979]" /> : <Eye size={24} className="text-[#AAB979]" />}
            </button>
          </div>
          <Link to="/forgot-password" className="block text-right text-sm text-[#AAB979] mt-2">
            忘記密碼？
          </Link>
        </div>
        
        {error && <p className="text-red-500 text-sm">{error}</p>}

        <button 
          type="submit" 
          className={`w-full py-2 bg-[#398378] text-white rounded-full ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? '登入中...' : '登入'}
        </button>
      </form>
      
      {/* <div className="w-full flex items-center">
        <div className="flex-grow h-px bg-[#E6E6E6]"></div>
        <span className="px-4 text-sm text-[#AAB979]">或</span>
        <div className="flex-grow h-px bg-[#E6E6E6]"></div>
      </div>
      
      <button className="w-full py-2 border border-[#398378] text-[#398378] rounded-full flex items-center justify-center">
        <img src="/images/icon/google.png" alt="Google" className="w-6 h-6 mr-2" />
        使用Google帳號登入
      </button> */}
    </div>
  );
};

export default Login;