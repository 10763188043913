import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { email, verificationToken, password, name, phoneCode, phone } = location.state || {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // 驗證電子郵件
      const verifyResponse = await axios.post('https://api.antqtech.com/Stanec/Account/VerifyCK', {
        email: email,
        verify: verificationToken,
        Emailcode: verificationCode
      });

      if (verifyResponse.data[0].state === 'OK') {
        // 電子郵件驗證成功，進行註冊
        const registerResponse = await axios.post('https://api.antqtech.com/Stanec/Account/Register', {
          email: email,
          password: password,
          verify: verificationToken,
          emailCode: verificationCode,
          name: name,
          uPhone_Code: phoneCode,
          uPhone: phone
        });

        if (registerResponse.data[0].state === 'OK') {
          setShowSuccessModal(true);
          // 3秒後自動關閉模態框並導航到登錄頁面
          setTimeout(() => {
            setShowSuccessModal(false);
            navigate('/login');
          }, 3000);
        } else {
          console.error('Registration failed');
          // 可以在這裡添加錯誤提示
        }
      } else {
        console.error('Email verification failed');
        // 可以在這裡添加錯誤提示
      }
    } catch (error) {
      console.error('Error:', error);
      // 可以在這裡添加錯誤提示
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-start p-5 pb-10 gap-6 w-full min-h-screen bg-gradient-to-b from-[#E9F3D1] to-white">
      <div className="flex items-center gap-2">
        <img src="/images/stanec-logo.png" alt="STANEC" className="w-[105px] h-6" />
      </div>
      
      <h1 className="text-2xl font-black text-[#398378] font-['Noto_Serif_TC']">驗證 Email</h1>
      
      <p className="text-base text-[#001528]">為確認您的信箱正確，我們已寄出驗證碼至：</p>
      
      <p className="text-lg text-[#006277]">{email}</p>
      
      <p className="text-base text-[#001528]">請在 10 分鐘內輸入驗證碼完成註冊，若沒收到驗證碼，可檢查垃圾郵件</p>
      
      <form onSubmit={handleSubmit} className="w-full space-y-6">
        <div>
          <label htmlFor="verificationCode" className="block text-sm text-[#1D443E] mb-2">驗證碼</label>
          <input
            id="verificationCode"
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className="w-full px-5 py-2.5 bg-white rounded-full shadow-md text-[#CCD5AE] placeholder-[#CCD5AE]"
            placeholder="輸入 6 碼驗證碼"
          />
        </div>
        
        <button 
          type="submit" 
          className="w-full py-2 bg-[#398378] text-white rounded-full disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? '處理中...' : '確認'}
        </button>
      </form>

      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold text-[#398378] mb-4">註冊成功</h2>
            <p>您的帳戶已成功註冊。即將跳轉到登錄頁面...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;