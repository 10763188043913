import React, { useState, useEffect } from 'react';
import { Share2, ArrowRight, QrCode, Edit, MoreVertical, Mail, Phone, MapPin, Link, Linkedin, Instagram, Facebook } from 'lucide-react';
import QRCodeModal from '../components/QRCodeModal';
import { fetchContactsDetail } from '../api/contactApi';
import { industryCodeMap } from '../data/carddata';

const CardDetails = ({ onClose, cid }) => {
  const [cardData, setCardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showQRCode, setShowQRCode] = useState(false);

  useEffect(() => {
    const loadCardData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchContactsDetail(cid, "string");
        setCardData(data);
      } catch (err) {
        setError('Failed to load card details');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadCardData();
  }, [cid]);

  if (isLoading) {
    return <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="text-2xl font-semibold text-gray-600">Loading...</div>
    </div>;
  }

  if (error) {
    return <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="text-2xl font-semibold text-red-600">{error}</div>
    </div>;
  }

  if (!cardData) {
    return null;
  }

  const socialIcons = {
    1: { icon: Instagram, name: 'Instagram' },
    2: { icon: Facebook, name: 'Facebook' },
    3: { icon: Linkedin, name: 'LinkedIn' },
    4: { icon: Link, name: 'Website' }
  };

  const industryName = cardData.indID && industryCodeMap[cardData.indID] 
    ? industryCodeMap[cardData.indID] 
    : '未知行業';

  const ContactItem = ({ Icon, text }) => (
    <div className="flex items-center mb-3">
      <Icon size={24} className="text-[#AAB979] mr-3" />
      <span className="text-lg text-[#222222]">{text}</span>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto pb-16 flex flex-col">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-10 bg-white">
        <div className="flex flex-col justify-center items-center px-5 py-2.5 gap-1 h-[82px] border-b border-[#E6E6E6]">
          <div className="flex items-center justify-between w-full h-10">
            <button onClick={onClose} className="w-[30px] h-[30px] text-[#AAB979] transform rotate-180">
              <ArrowRight size={24} />
            </button>
            <h2 className="text-xl font-semibold text-[#222222] flex-grow text-center">{cardData.cName}</h2>
            <div className="flex space-x-2">
              <button className="w-10 h-10 bg-white bg-opacity-80 rounded-full flex items-center justify-center">
                <Share2 size={24} className="text-[#398378]" />
              </button>
              <button 
                className="w-10 h-10 bg-white bg-opacity-80 rounded-full flex items-center justify-center"
                onClick={() => setShowQRCode(true)}
              >
                <QrCode size={24} className="text-[#398378]" />
              </button>
            </div>
          </div>
          <div className="w-full text-sm text-[#808080]">@{cardData.cid}</div>
        </div>
      </header>

      {/* Main Content */}
      <div className="mt-[82px] flex-grow flex flex-col">
        {/* Background */}
        <div className="h-[120px] bg-gradient-to-r from-blue-400 to-blue-600"
             style={cardData.bpFileName ? {backgroundImage: `url(https://api.antqtech.com/stanec/photo/BackgroundPhoto/${cardData.bpFileName})`, backgroundSize: 'cover'} : {}}>
        </div>        
        
        {/* Profile Section */}
        <div className="relative px-5 -mt-12 flex justify-between items-end">
          <div className="w-[98px] h-[98px] rounded-full bg-gradient-to-br from-[#E9F3D1] to-[#72A36A] border-2 border-white shadow-lg overflow-hidden flex items-center justify-center">
            <img src={`https://api.antqtech.com/stanec/photo/profilePhoto/${cardData.ppFileName}`} alt="Profile" className="w-full h-full object-cover" />
          </div>
          <div className="flex space-x-2">
            <button className="w-10 h-10 bg-slate-200 bg-opacity-80 rounded-full flex items-center justify-center">
              <Edit size={24} className="text-[#4D4D4D]" />
            </button>
            <button className="w-10 h-10 bg-slate-200 bg-opacity-80 rounded-full flex items-center justify-center">
              <MoreVertical size={24} className="text-[#4D4D4D]" />
            </button>
          </div>
        </div>

        {/* Profile Info */}
        <div className="px-5 pt-4 bg-white">
          <h1 className="font-['Noto_Serif_TC'] font-black text-2xl text-[#222222] tracking-[0.02em] mb-1">{cardData.cName}</h1>
          <p className="text-base text-[#398378] mb-1">{cardData.cid}</p>
          <p className="text-base text-[#222222] mb-3">{cardData.cCompany_Name} · {cardData.cJob_Title}</p>
          <div className="flex items-center mb-5">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
              <path d="M4.375 17.5V5.25C4.375 4.42157 5.04657 3.75 5.875 3.75H15.125C15.9534 3.75 16.625 4.42157 16.625 5.25V17.5L10.5 14L4.375 17.5Z" fill="url(#paint0_linear_1721_25167)"/>
              <defs>
                <linearGradient id="paint0_linear_1721_25167" x1="10.5" y1="3.75" x2="10.5" y2="17.5" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#398378"/>
                  <stop offset="1" stopColor="#EFD758"/>
                </linearGradient>
              </defs>
            </svg>
            <span className="text-sm text-[#AAB979]">已有 {cardData.cSave_Count} 人收藏此名片</span>
          </div>
          <div className="flex space-x-4 mb-5">
            {cardData.linkList.map((link, index) => {
              const SocialIcon = socialIcons[link.link_ID]?.icon || Link;
              return (
                <a key={index} href={link.link_Url} className="w-9 h-9 flex items-center justify-center rounded-full bg-[#398378] text-white">
                  <SocialIcon size={20} />
                </a>
              );
            })}
          </div>
          <div className="relative mb-5">
            <svg className="absolute top-0 left-0" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.875 8.75H14.875M7.875 12.25H14.875M17.5 3.5H4.375C3.54657 3.5 2.875 4.17157 2.875 5V16C2.875 16.8284 3.54657 17.5 4.375 17.5H17.5C18.3284 17.5 19 16.8284 19 16V5C19 4.17157 18.3284 3.5 17.5 3.5Z" stroke="#AAB979" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className="pl-6 text-sm text-[#222222]">{cardData.cDescription}</p>
          </div>
        </div>

        {/* Contact Info */}
        <div className="px-5 py-5 bg-white flex-grow">
          <div className="flex items-center mb-5">
            <div className="w-[2px] h-[30px] bg-gradient-to-b from-[#AAB979] to-[#EFD758] rounded-full mr-2"></div>
            <h2 className="text-xl font-semibold text-[#222222]">聯絡資訊</h2>
          </div>
          <div className="flex items-center mb-5">
            <div className="w-[60px] h-[60px] mr-2 relative">
              {cardData.cCompany_LogoFileName ? (
                <img src={`https://api.antqtech.com/stanec/photo/CompanyLogo/${cardData.cCompany_LogoFileName}`} alt="Company Logo" className="w-full h-full object-cover rounded-lg" />
              ) : (
                <div className="w-full h-full bg-gradient-to-b from-[#6390E7] to-[#0053F4] rounded-lg flex items-center justify-center text-white text-2xl font-bold">
                  {cardData.cCompany_Name.charAt(0)}
                </div>
              )}
            </div>
            <div>
              <p className="text-sm text-[#808080]">{industryName}</p>
              <p className="text-xl text-[#222222]">{cardData.cCompany_Name}</p>
              <p className="text-lg text-[#398378]">{cardData.cJob_Title}</p>
            </div>
          </div>
          <div className="space-y-3">
            <ContactItem Icon={Mail} text={cardData.cEmail || 'N/A'} />
            <ContactItem Icon={Phone} text={cardData.cTel || 'N/A'} />
            <ContactItem Icon={Phone} text={cardData.cMobile || 'N/A'} />
            <ContactItem Icon={MapPin} text={`${cardData.cAddress} (查看地圖)` || 'N/A'} />
          </div>
        </div>
      </div>

      {showQRCode && (
        <QRCodeModal 
          onClose={() => setShowQRCode(false)} 
          username={cardData.cid} 
        />
      )}
    </div>
  );
};

export default CardDetails;