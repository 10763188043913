import React, { useState, useEffect } from 'react';
import { X, Calendar, Clock } from 'lucide-react';
import ActivityPurposeInput from './ActivityPurposeInput';
import axios from 'axios';
import { fetchContactsDetail, addInteraction, fetchAllInteractions } from '../api/contactApi';
import { useParams, useLocation } from 'react-router-dom';

const AddActivityForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    aid: '',
    interaction_Title: '',
    interaction_Note: '',
    interaction_DateTime: '',
    date: '',
    time: ''
  });
  const [contactId, setContactId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const cid = pathParts[pathParts.length - 1];
  const [interactions, setInteractions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contactData = await fetchContactsDetail(cid, "string");
        setContactId(contactData.ucid);
        
        // const interactionsResult = await fetchAllInteractions(cid);
        // console.log(interactionsResult)
        // if (interactionsResult.success) {
        //   console.log(interactionsResult)
        //   setInteractions(interactionsResult.data);
        // } else {
        //   console.error('Failed to fetch interactions:', interactionsResult.error);
        // }
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [cid, isOpen]);

  if (!isOpen || isLoading) return null;

  const combineDateAndTime = (date, time) => {
    return `${date} ${time}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handlePurposeChange = (aid) => {
    setFormData(prev => ({ ...prev, aid }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    
    if (!contactId) {
      alert('無法獲取聯絡人ID，請稍後再試');
      setIsSubmitting(false);
      return;
    }
  
    const payload = {
      ucid: contactId,
      aid: parseInt(formData.aid),
      interaction_Title: formData.interaction_Title,
      interaction_Note: formData.interaction_Note,
      interaction_DateTime: formData.interaction_DateTime
    };
  
    try {
      console.log(payload);
      const result = await addInteraction(payload);
      if (result.success) {
        onClose();
        alert('活動添加成功');
      } else {
        throw new Error(result.error || '添加活動失敗');
      }
    } catch (error) {
      console.error('Error adding activity:', error);
      alert('添加活動失敗: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-end justify-center">
      <div className="bg-white w-full max-w-[375px] h-[706px] rounded-t-xl shadow-lg flex flex-col animate-slide-up">
        <div className="flex justify-between items-center p-5 border-b border-[#CCD5AE]">
          <h2 className="text-xl font-semibold text-[#222222]">新增活動</h2>
          <button onClick={onClose}>
            <X size={24} className="text-[#808080]" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-5 space-y-6 overflow-y-auto flex-grow">
          <div className="space-y-2">
            <label className="text-sm text-[#1D443E]">活動時間</label>
            <div className="flex space-x-2">
              <div className="flex-1 flex items-center bg-[#F5FAEB] rounded-full px-5 py-2.5">
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={(e) => {
                  const newDateTime = combineDateAndTime(e.target.value, formData.time || '00:00');
                  setFormData(prev => ({ 
                    ...prev, 
                    date: e.target.value, 
                    interaction_DateTime: newDateTime 
                  }));
                }}
                className="bg-transparent flex-grow text-[#222222] placeholder-[#CCD5AE]"
              />
              </div>
              <div className="flex-1 flex items-center bg-[#F5FAEB] rounded-full px-5 py-2.5">
              <input
                type="time"
                name="time"
                value={formData.time}
                onChange={(e) => {
                  const newDateTime = combineDateAndTime(formData.date, e.target.value);
                  setFormData(prev => ({ 
                    ...prev, 
                    time: e.target.value, 
                    interaction_DateTime: newDateTime 
                  }));
                }}
                className="bg-transparent flex-grow text-[#222222] placeholder-[#CCD5AE]"
              />
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <label className="text-sm text-[#1D443E]">活動目的</label>
            <ActivityPurposeInput
              value={formData.aid}
              onChange={handlePurposeChange}
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm text-[#1D443E]">主旨</label>
            <input
              type="text"
              name="interaction_Title"
              value={formData.interaction_Title}
              onChange={handleChange}
              className="w-full bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222] placeholder-[#CCD5AE]"
              placeholder="輸入主旨"
            />
          </div>

          <div className="space-y-2">
            <div className="flex justify-between">
              <label className="text-sm text-[#1D443E]">活動內容</label>
              <span className="text-sm text-[#808080]">{formData.interaction_Note.length} / 300 字</span>
            </div>
            <textarea
              name="interaction_Note"
              value={formData.interaction_Note}
              onChange={handleChange}
              className="w-full h-40 bg-[#F5FAEB] rounded-xl px-5 py-2.5 text-[#222222] placeholder-[#CCD5AE] resize-none"
              placeholder="輸入活動內容"
              maxLength={300}
            />
          </div>
        </form>

        <div className="flex items-center justify-between p-5 border-t border-[#E6E6E6]">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-[#398378] text-[#398378] rounded-full"
          >
            取消
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isSubmitting}
            className={`px-4 py-2 bg-[#398378] text-white rounded-full flex-grow ml-2 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSubmitting ? '處理中...' : '儲存'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddActivityForm;