import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import AddActivityForm from './AddActivityForm';

const ActivitySection = () => {
    const [showAddActivity, setShowAddActivity] = useState(false);
  return (
    <div className="flex flex-col justify-center items-start p-5 w-full">
      <div className="flex flex-row items-center mb-5 w-full">
        <div className="w-0.5 h-[30px] bg-gradient-to-b from-[#AAB979] to-[#EFD758] rounded-full mr-2"></div>
        <h3 className="text-xl font-semibold text-[#222222]">活動紀錄</h3>
      </div>
      
      <div className="flex flex-col justify-center items-center w-full gap-3">
        <div className="relative w-[120px] h-[120px]">
            <div className="absolute inset-0 bg-[#F2F2F2] rounded-full"></div>
            <img 
                src="/images/icon/Union.png" 
                alt="dialogue" 
                className="absolute inset-0 w-full h-full object-cover z-10"
            />
        </div>
        <p className="text-sm text-[#808080]">尚未有任何活動紀錄</p>
        <button 
            className="flex items-center justify-center px-4 py-1 bg-[#AAB979] rounded-full"
            onClick={() => setShowAddActivity(true)}
        >
            <Plus size={21} className="text-white" />
            <span className="text-sm text-white ml-1">新增活動</span>
        </button>
        <AddActivityForm 
            isOpen={showAddActivity} 
            onClose={() => setShowAddActivity(false)} 
        />
      </div>
    </div>
  );
};

export default ActivitySection;