import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Share2, QrCode, Mail, Phone, MapPin, Instagram, Youtube, MessageCircle, Globe } from 'lucide-react';
import { fetchContactsDetail } from '../api/contactApi'; // 確保路徑正確
import { useAuth } from '../context/AuthContext'; // 確保路徑正確

const SharedCardDetail = () => {
  const [cardData, setCardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { shareCode } = useParams(); // shareCode 實際上就是聯繫人ID
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const loadCardData = async () => {
      try {
        setIsLoading(true);
        // 注意：這裡直接使用 shareCode 作為 cid，可能需要調整
        const data = await fetchContactsDetail(shareCode, "string");
        setCardData(data);
      } catch (err) {
        setError('Failed to load card details');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadCardData();
  }, [shareCode]);

  const handleCollect = () => {
    if (isAuthenticated()) {
      // 實現收藏邏輯
      console.log('Collecting card...');
    } else {
      // 重定向到登錄頁面
      navigate('/login');
    }
  };

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="text-2xl font-semibold text-red-600">{error}</div>
    </div>;
  }

  if (!cardData) {
    return null;
  }

  const socialIcons = {
    1: { icon: Instagram, name: 'Instagram' },
    2: { icon: Youtube, name: 'Youtube' },
    3: { icon: MessageCircle, name: 'Discord' },
    4: { icon: Globe, name: 'Website' },
    5: { icon: MessageCircle, name: 'Line' }
  };

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto pb-16 flex flex-col">
      {/* Header */}
      <header className="sticky top-0 bg-white z-10 shadow-md">
        <div className="flex justify-between items-center p-4">
          <h1 className="text-lg font-semibold">共享名片</h1>
          <div className="flex space-x-2">
            <button className="p-2 bg-gray-100 rounded-full">
              <Share2 size={20} className="text-gray-600" />
            </button>
            <button className="p-2 bg-gray-100 rounded-full">
              <QrCode size={20} className="text-gray-600" />
            </button>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="flex-grow flex flex-col">
        {/* Background Image */}
        <div className="h-32 bg-gradient-to-r from-blue-400 to-blue-600"
             style={cardData.bpFileName ? {backgroundImage: `url(https://api.antqtech.com/stanec/photo/BackgroundPhoto/${cardData.bpFileName})`, backgroundSize: 'cover'} : {}}>
        </div>
        
        {/* Profile Section */}
        <div className="relative px-5 -mt-16 flex justify-between items-end">
          <div className="w-32 h-32 rounded-full bg-white p-1 shadow-lg">
            <img 
              src={`https://api.antqtech.com/stanec/photo/profilePhoto/${cardData.ppFileName}`} 
              alt="Profile" 
              className="w-full h-full rounded-full object-cover"
            />
          </div>
          <button 
            onClick={handleCollect}
            className="px-4 py-2 bg-green-500 text-white rounded-full shadow-md"
          >
            {isAuthenticated() ? '收藏名片' : '登入後收藏'}
          </button>
        </div>

        {/* Card Info */}
        <div className="px-5 pt-4">
          <h2 className="text-2xl font-bold">{cardData.cName}</h2>
          <p className="text-gray-600">{cardData.cJob_Title}</p>
          <p className="text-gray-600">{cardData.cCompany_Name}</p>
          
          <div className="mt-4 space-y-2">
            <div className="flex items-center">
              <Mail className="w-5 h-5 mr-2 text-gray-400" />
              <span>{cardData.cEmail}</span>
            </div>
            {cardData.cTel && (
              <div className="flex items-center">
                <Phone className="w-5 h-5 mr-2 text-gray-400" />
                <span>{cardData.cTel}</span>
              </div>
            )}
            {cardData.cAddress && (
              <div className="flex items-center">
                <MapPin className="w-5 h-5 mr-2 text-gray-400" />
                <span>{cardData.cAddress}</span>
              </div>
            )}
          </div>

          {/* Social Links */}
          <div className="mt-4 flex space-x-2">
            {cardData.linkList.map((link) => {
              const SocialIcon = socialIcons[link.link_ID]?.icon || Globe;
              return (
                <a 
                  key={link.link_ID} 
                  href={link.link_Url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="p-2 bg-gray-100 rounded-full"
                >
                  <SocialIcon size={20} className="text-gray-600" />
                </a>
              );
            })}
          </div>

          {/* Description */}
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">簡介</h3>
            <p className="text-gray-700">{cardData.cDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedCardDetail;