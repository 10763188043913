import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';

const CardContext = createContext();

export const CardProvider = ({ children }) => {
  const [cardData, setCardData] = useState({
    cards: [],
    lastFetched: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const { isAuthenticated, user, logout } = useAuth();

  const fetchCards = async () => {
    if (!isAuthenticated() || !user) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.post(
        'https://api.antqtech.com/Stanec/Card/SelCardList',
        '',
        {
          headers: {
            'Authorization': token,
            'accept': '*/*'
          }
        }
      );

      if (response.data[0].state === "OK") {
        const newCardData = {
          cards: response.data[0].cardList,
          lastFetched: new Date().getTime(),
        };
        setCardData(newCardData);
        setIsNewUser(newCardData.cards.length === 0);
        console.log("user",newCardData)
        localStorage.setItem('cardData', JSON.stringify(newCardData));
      } else {
        throw new Error(response.data[0].msg);
      }
    } catch (error) {
      console.error('Failed to fetch cards:', error);
      if (error.response) {
        if (error.response.status === 400) {
          setIsNewUser(true);
          setCardData({ cards: [], lastFetched: new Date().getTime() });
        } else if (error.response.status === 401) {
          logout();
        } else {
          setError('Failed to fetch cards. Please try again.');
        }
      } else {
        setError('Failed to fetch cards. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const refreshCards = () => {
    fetchCards();
  };

  useEffect(() => {
    if (isAuthenticated() && user) {
      fetchCards();
    }
  }, [isAuthenticated, user]);

  return (
    <CardContext.Provider value={{ cardData, refreshCards, isLoading, error, isNewUser }}>
      {children}
    </CardContext.Provider>
  );
};

export const useCards = () => useContext(CardContext);