import React from 'react';
import { ChevronRight } from 'lucide-react';

const RecentContact = ({ contact }) => (
  <div className="flex-shrink-0 flex flex-col items-center justify-center w-[60px] h-[82px] gap-1">
    <div className="w-[60px] h-[60px] rounded-full overflow-hidden bg-gradient-to-br from-[#D1F1F3] to-[#95DC8A] border-2 border-white">
      <img src={contact.avatar} alt={contact.name} className="w-full h-full object-cover" />
    </div>
    <span className="w-full text-xs text-center text-[#808080] truncate">{contact.name}</span>
  </div>
);

const ContactItem = ({ contact, rank }) => (
    <div className="flex items-center py-3 px-0 gap-3 w-full min-h-[90px] border-b border-[#E6E6E6]">
      <div className="w-[60px] h-[60px] rounded-full overflow-hidden bg-gradient-to-br from-[#D1F1F3] to-[#95DC8A] border-2 border-white flex-shrink-0">
        <img src={contact.avatar} alt={contact.name} className="w-full h-full object-cover" />
      </div>
      <div className="flex flex-col justify-center items-start flex-grow gap-0.5">
        <h3 className="w-full font-['Noto_Sans_TC'] text-base font-normal leading-tight tracking-[0.02em] text-[#222222] line-clamp-1">
          {contact.name}
        </h3>
        <p className="w-full font-['Noto_Sans_TC'] text-sm font-normal leading-tight tracking-[0.02em] text-[#AAB979] line-clamp-1">
          {contact.company} · {contact.title}
        </p>
        <p className="w-full font-['Noto_Sans_TC'] text-base font-normal leading-tight tracking-[0.02em] text-[#222222]">
          {contact.contactTimes} 次
        </p>
      </div>
      <ChevronRight className="text-[#AAB979] w-6 h-6 flex-shrink-0" />
    </div>
  );

const ActivityPurposeItem = ({ purpose, count, color }) => (
  <div className="flex items-center gap-2 mb-4">
    <div className="w-4 h-4 rounded" style={{ backgroundColor: color }}></div>
    <span className="text-lg text-[#222222] flex-grow">{purpose}</span>
    <span className="text-lg text-[#222222] text-right">{count} 次</span>
  </div>
);

const ActivityRecord = () => {
  // 模擬數據
  const recentContacts = [
    { id: 1, name: '王小麗', avatar: '/images/head.png' },
    { id: 2, name: 'Alex Wang', avatar: '/images/head.png' },
    { id: 3, name: '黃小君', avatar: '/images/head.png' },
    { id: 4, name: '陳小華', avatar: '/images/head.png' },
    { id: 5, name: '林小志', avatar: '/images/head.png' },
    { id: 6, name: '張大明', avatar: '/images/head.png' },
    { id: 7, name: '李小英', avatar: '/images/head.png' },
  ];

  const topContacts = [
    { id: 1, name: '王小麗', avatar: '/images/head.png', company: 'XYZ資訊科技', title: '前端工程師', contactTimes: 1234 },
    { id: 2, name: 'Alex Wang', avatar: '/images/head.png', company: 'ALX Fintech', title: 'Information Security Engineer', contactTimes: 1000 },
    { id: 3, name: '黃小君', avatar: '/images/head.png', company: 'Yellow設計', title: '網頁設計師', contactTimes: 823 },
    { id: 4, name: '陳小華', avatar: '/images/head.png', company: 'ABC Co.', title: '資料分析師', contactTimes: 459 },
  ];

  const activityPurposes = [
    { purpose: '業務洽談', count: 1000, color: '#F62362' },
    { purpose: '文件請求', count: 200, color: '#1ABE04' },
    { purpose: '會議討論', count: 34, color: '#E9770C' },
  ];

  return (
    <div className="flex flex-col items-start p-0 gap-6 w-full bg-white">
      {/* 活動紀錄標題 */}
      <div className="flex items-center gap-2 w-full">
        <div className="w-0.5 h-[30px] bg-gradient-to-b from-[#AAB979] to-[#EFD758] rounded-full"></div>
        <h2 className="text-xl font-semibold text-[#222222]">活動紀錄</h2>
      </div>

      {/* 最近聯絡 */}
      <div className="w-full">
        <h2 className="text-base font-semibold text-[#222222] mb-5">最近聯絡</h2>
        <div className="flex space-x-5 overflow-x-auto pb-2 scrollbar-hide">
          {recentContacts.map(contact => (
            <RecentContact key={contact.id} contact={contact} />
          ))}
        </div>
      </div>

      {/* 最常聯絡 */}
      <div className="w-full">
        <h2 className="text-base font-semibold text-[#222222] mb-5">最常聯絡</h2>
        <ContactItem contact={topContacts[0]} rank="1" />
      </div>

      {/* 活動目的 */}
      <div className="w-full">
        <h2 className="text-base font-semibold text-[#222222] mb-5">活動目的</h2>
        {activityPurposes.map((purpose, index) => (
          <ActivityPurposeItem key={index} {...purpose} />
        ))}
      </div>

      {/* 聯繫次數 2~4 名的聯絡人 */}
      <div className="w-full">
        {topContacts.slice(1, 4).map((contact, index) => (
          <ContactItem key={contact.id} contact={contact} rank={index + 2} />
        ))}
      </div>

      {/* 顯示全部 */}
      <button className="w-full text-right text-base text-[#AAB979]">
        顯示全部
      </button>
    </div>
  );
};

export default ActivityRecord;