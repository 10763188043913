import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const WelcomeCard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleCreateCard = () => {
    if (user?.userName) {
      navigate(`/${user.userName}/create-card`);
    }
  };

  return (
    <div className="flex flex-col items-start p-5 pb-10 gap-6 w-full h-full bg-white">
      <div className="w-full flex flex-col items-start p-0 gap-0 bg-white rounded-xl shadow-md mt-14">
        <div className="w-full h-[120px] bg-gradient-to-r from-[#E9F3D1] to-white flex items-end p-5">
          <div className="w-20 h-20 bg-[#E9F3D1] border-2 border-white rounded-full flex items-center justify-center">
            <svg className="w-10 h-10 text-[#CCD5AE]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>
        <div className="flex flex-col items-start p-5 gap-3">
          <div className="flex items-center gap-1">
            <div className="w-[30px] h-[30px] bg-[#398378] rounded-full flex items-center justify-center">
              <span className="text-white text-xs font-bold">ST</span>
            </div>
            <h2 className="text-xl font-black text-[#222222] font-['Noto_Serif_TC']">{user.userName}</h2>
          </div>
          <h3 className="text-xl font-black text-[#398378]">新增第一張 Stanec 名片</h3>
          <p className="text-sm text-[#4D4D4D]">開始建立人脈，拓展你的業務吧！</p>
          <div className="w-full h-[1px] bg-[#E6E6E6] my-3"></div>
          <div className="flex items-center gap-2 text-[#E6E6E6]">
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 10V14C20 16.2091 18.2091 18 16 18H8C5.79086 18 4 16.2091 4 14V10C4 7.79086 5.79086 6 8 6H16C18.2091 6 20 7.79086 20 10Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M16 14L12 11L8 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <span className="text-base">0912345678</span>
          </div>
          <div className="flex items-center gap-2 text-[#E6E6E6]">
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M22 6L12 13L2 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <span className="text-base">company@mail.com</span>
          </div>
          <button 
            onClick={handleCreateCard}
            className="w-full py-3 mt-3 bg-[#398378] text-white rounded-full text-base font-normal"
          >
            立即開始
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;