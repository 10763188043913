import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CreditCard, Book, Camera, BarChart2, Settings } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

const BottomNavigation = () => {
  const location = useLocation();
  const { user, isAuthenticated } = useAuth();

  // 如果用戶未認證,不顯示底部導航
  if (!isAuthenticated()) {
    return null;
  }

  const navItems = [
    { icon: CreditCard, label: '我的名片', path: '/' },
    { icon: Book, label: '名片夾', path: '/contacts' },
    // { icon: Camera, label: '掃描收藏', path: '/scan' },
    { icon: BarChart2, label: '分析', path: '/analysis' },
    { icon: Settings, label: '設定', path: '/settings' },
  ];
  
  return (
    <nav className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full h-[62px] bg-white shadow-[0px_0px_12px_rgba(0,0,0,0.1)] flex justify-between items-center px-5">
      {navItems.map(({ icon: Icon, label, path }) => (
        <Link
          key={path}
          to={`/${user.userName}${path}`}
          className="flex flex-col items-center justify-center"
        >
          <div className={`w-[55px] h-[42px] flex flex-col items-center justify-center ${
            path === '/scan' ? 'relative' : ''
          }`}>
            {path === '/scan' && (
              <div className="absolute -top-6 w-[80px] h-[80px] bg-gradient-to-b from-[#E9F3D1] to-[#FAEDCD] rounded-lg flex flex-col items-center justify-center">
                <Icon size={30} className="text-[#398378] mb-1" />
                <span className="text-xs font-medium text-[#398378]">{label}</span>
              </div>
            )}
            {path !== '/scan' && (
              <>
                <Icon 
                  size={24} 
                  className={`mb-1 ${location.pathname === `/${user.userName}${path}` ? 'text-[#398378]' : 'text-[#4D4D4D]'}`} 
                />
                <span className={`text-xs font-medium ${location.pathname === `/${user.userName}${path}` ? 'text-[#398378]' : 'text-[#4D4D4D]'}`}>
                  {label}
                </span>
              </>
            )}
          </div>
        </Link>
      ))}
    </nav>
  );
};

export default BottomNavigation;