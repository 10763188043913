import React, { useState, useRef, useEffect } from 'react';
import { Camera, ImageIcon, Plus, X, MapPin, ChevronDown } from 'lucide-react';

const CardForm = ({ initialData, onSubmit }) => {
  const [formData, setFormData] = useState(initialData);
  const avatarInputRef = useRef(null);
  const coverPhotoInputRef = useRef(null);
  const companyLogoInputRef = useRef(null);

  const industryCodeMap = {
    1: '農、林、漁、牧業',
    2: '礦業及土石採取業',
    3: '製造業',
    4: '電力及燃氣供應業',
    5: '用水供應及污染整治業',
    6: '營造業',
    7: '批發及零售業',
    8: '運輸及倉儲業',
    9: '住宿及餐飲業',
    10: '資訊及通訊傳播業',
    11: '金融及保險業',
    12: '不動產業',
    13: '專業、科學及技術服務業',
    14: '支援服務業',
    15: '公共行政及國防；強制性社會安全',
    16: '教育服務業',
    17: '醫療保健及社會工作服務業',
    18: '藝術、娛樂及休閒服務業',
    19: '其他服務業'
  };

  useEffect(() => {
    // 每次 formData 更新時,調用 onSubmit
    onSubmit(formData);
  }, [formData, onSubmit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (type, field, value) => {
    setFormData(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [field]: value
      }
    }));
  };

  const handleFileUpload = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        console.log(`${field} base64:`, base64String.slice(0, 50) + '...'); // 記錄前50個字符
        console.log(`${field} base64:`, base64String); // 記錄前50個字符
        setFormData(prev => ({
          ...prev,
          [field]: {
            base64: base64String,
            extension: '.' +file.name.split('.').pop().toLowerCase()
          }
        }));
      };
      reader.onerror = (error) => {
        console.error('文件讀取錯誤:', error);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddSocialLink = () => {
    setFormData(prev => ({
      ...prev,
      socialLinks: [...prev.socialLinks, { platform: '', url: '' }]
    }));
  };

  const handleRemoveSocialLink = (index) => {
    setFormData(prev => ({
      ...prev,
      socialLinks: prev.socialLinks.filter((_, i) => i !== index)
    }));
  };

  const handleSocialLinkChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      socialLinks: prev.socialLinks.map((link, i) => 
        i === index ? { ...link, [field]: value } : link
      )
    }));
  };

  return (
    <div className="bg-white min-h-screen pb-10">
      {/* 頭像和封面照片 */}
      <div className="mb-6 relative mt-14">
        <div className="relative w-full h-[140px] bg-gradient-to-r from-[#E9F3D1] to-white rounded-t-lg overflow-hidden">
          {formData.coverPhoto ? (
            <img src={formData.coverPhoto.base64} alt="Cover" className="w-full h-full object-cover" />
          ) : (
            <div className="absolute inset-0 flex items-center justify-center">
              {/* 可以添加一個預設圖片或者提示文字 */}
            </div>
          )}
        </div>
        
        <div className="absolute bottom-3 left-5 transform flex items-end space-x-4">
          <div className="flex flex-col items-center">
            <div 
              className="w-[80px] h-[80px] bg-[#E9F3D1] border-2 border-white rounded-full flex items-center justify-center overflow-hidden"
              onClick={() => avatarInputRef.current.click()}
            >
              {formData.avatar ? (
                <img src={formData.avatar.base64} alt="Avatar" className="w-full h-full object-cover" />
              ) : (
                <img src="/images/head.png" alt="Avatar" className="w-full h-full object-cover" />
              )}
            </div>
          </div>
        </div>

        {/* 按鈕容器 */}
        <div className="absolute bottom-2 left-[120px] right-5 flex justify-between items-center transform">
          <button 
            type="button"
            onClick={() => avatarInputRef.current.click()}
            className="px-3 py-1 bg-white border border-[#AAB979] rounded-full text-[#AAB979] text-sm flex items-center"
          >
            <Camera size={16} className="mr-1" />
            大头贴照
          </button> 
          <button 
            type="button"
            onClick={() => coverPhotoInputRef.current.click()} 
            className="px-3 py-1 bg-white border border-[#AAB979] rounded-full text-[#AAB979] text-sm flex items-center"
          >
            <Camera size={16} className="mr-1" />
            封面照片
          </button>
        </div>
      </div>

      <div className="px-5">
        {/* 名片ID */}
        <div className="mb-6">
          <label className="block text-sm text-[#1D443E] mb-2">名片ID *</label>
          <div className="flex items-center bg-[#F5FAEB] rounded-full px-2.5 py-2.5">
            <span className="text-[#AAB979] mr-2">@</span>
            <input
              type="text"
              name="cardId"
              value={formData.cardId.replace('@', '')}
              onChange={(e) => setFormData({ ...formData, cardId: '@' + e.target.value })}
              className="bg-transparent flex-1 text-[#222222] focus:outline-none"
              placeholder="輸入名片ID"
            />
          </div>
        </div>

        {/* 姓名 */}
        <div className="mb-6">
          <label className="block text-sm text-[#1D443E] mb-2">姓名 *</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222]"
            placeholder="輸入姓名"
          />
        </div>

        {/* Email */}
        <div className="mb-6">
          <label className="block text-sm text-[#1D443E] mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222]"
            placeholder="輸入Email"
          />
        </div>

        {/* 公司名稱 */}
        <div className="mb-6">
          <label className="block text-sm text-[#1D443E] mb-2">公司名稱</label>
          <input
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className="w-full bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222]"
            placeholder="輸入公司名稱"
          />
        </div>

      {/* 公司Logo */}
      <div className="mb-6 flex items-center">
        <div 
          className="w-20 h-20 bg-[#E9F3D1] rounded-full flex items-center justify-center overflow-hidden mr-4"
          onClick={() => companyLogoInputRef.current.click()}
        >
          {formData.companyLogo ? (
            <img src={formData.companyLogo.base64} alt="Company Logo" className="w-full h-full object-cover" />
          ) : (
            <ImageIcon size={40} className="text-[#CCD5AE]" />
          )}
        </div>
        <button 
          type="button"
          onClick={() => companyLogoInputRef.current.click()}
          className="px-3 py-1 bg-white border border-[#AAB979] rounded-full text-[#AAB979] text-sm flex items-center"
        >
          <Camera size={16} className="mr-1" />
          公司Logo
        </button>
      </div>


        {/* 職稱 */}
        <div className="mb-6">
          <label className="block text-sm text-[#1D443E] mb-2">職稱</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="w-full bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222]"
            placeholder="輸入職稱"
          />
        </div>

        {/* 行業類別 */}
        <div className="mb-6">
          <label className="block text-sm text-[#1D443E] mb-2">行業類別</label>
          <div className="relative">
            <select
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              className="w-full bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222] appearance-none"
            >
              <option value="">選擇行業類別</option>
              {Object.entries(industryCodeMap).map(([code, name]) => (
                <option key={code} value={code}>{name}</option>
              ))}
            </select>
            <ChevronDown size={20} className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#AAB979]" />
          </div>
        </div>

        {/* 聯絡電話 */}
        <div className="mb-6">
          <label className="block text-sm text-[#1D443E] mb-2">聯絡電話</label>
          
          {/* 公司電話 */}
          <div className="mb-2">
            <div className="flex space-x-2 mb-1">
              <select
                value={formData.companyPhone.areaCode}
                onChange={(e) => handlePhoneChange('companyPhone', 'areaCode', e.target.value)}
                className="w-24 bg-[#F5FAEB] rounded-full px-3 py-2.5 text-[#222222]"
              >
                <option value="+886">+886</option>
                <option value="+1">+1</option>
              </select>
              <input
                type="tel"
                value={formData.companyPhone.number}
                onChange={(e) => handlePhoneChange('companyPhone', 'number', e.target.value)}
                className="flex-1 bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222]"
                placeholder="公司電話"
              />
            </div>
            <input
              type="text"
              value={formData.companyPhone.extension}
              onChange={(e) => handlePhoneChange('companyPhone', 'extension', e.target.value)}
              className="w-full bg-[#F5FAEB] rounded-full px-3 py-2.5 text-[#222222]"
              placeholder="分機"
            />
          </div>
          
          {/* 手機號碼 */}
          <div className="flex space-x-2">
            <select
              value={formData.personalPhone.areaCode}
              onChange={(e) => handlePhoneChange('personalPhone', 'areaCode', e.target.value)}
              className="w-24 bg-[#F5FAEB] rounded-full px-3 py-2.5 text-[#222222]"
            >
              <option value="+886">+886</option>
              <option value="+1">+1</option>
            </select>
            <input
              type="tel"
              value={formData.personalPhone.number}
              onChange={(e) => handlePhoneChange('personalPhone', 'number', e.target.value)}
              className="flex-1 bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222]"
              placeholder="手機號碼"
            />
          </div>
        </div>

        {/* 聯絡地址 */}
        <div className="mb-6">
          <label className="block text-sm text-[#1D443E] mb-2">聯絡地址</label>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="flex-1 bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222]"
              placeholder="輸入地址"
            />
            <button type="button" className="w-11 h-11 bg-white border border-[#AAB979] rounded-full flex items-center justify-center">
              <MapPin size={24} className="text-[#AAB979]" />
            </button>
          </div>
        </div>

        {/* 社群媒體連結 */}
        <div className="mb-6">
          <label className="block text-sm text-[#1D443E] mb-2">社群媒體連結</label>
          {formData.socialLinks.map((link, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <select
                value={link.platform}
                onChange={(e) => handleSocialLinkChange(index, 'platform', e.target.value)}
                className="w-1/3 bg-[#F5FAEB] rounded-full px-3 py-2.5 text-[#222222]"
              >
                <option value="">選擇平台</option>
                <option value="facebook">Facebook</option>
                <option value="twitter">Twitter</option>
                <option value="linkedin">LinkedIn</option>
                <option value="instagram">Instagram</option>
              </select>
              <input
                type="url"
                value={link.url}
                onChange={(e) => handleSocialLinkChange(index, 'url', e.target.value)}
                className="flex-1 bg-[#F5FAEB] rounded-full px-5 py-2.5 text-[#222222]"
                placeholder="輸入連結"
              />
              <button
                type="button"
                onClick={() => handleRemoveSocialLink(index)}
                className="w-11 h-11 bg-white border border-[#AAB979] rounded-full flex items-center justify-center"
              >
                <X size={20} className="text-[#AAB979]" />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddSocialLink}
            className="mt-2 px-3 py-1 bg-white border border-[#AAB979] rounded-full text-[#AAB979] text-sm flex items-center"
          >
            <Plus size={16} className="mr-1" />
            新增社群
          </button>
        </div>

        {/* 名片簡介 */}
        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <label className="text-sm text-[#1D443E]">名片簡介</label>
            <span className="text-sm text-[#808080]">{formData.bio.length} / 150 字</span>
          </div>
          <textarea
            name="bio"
            value={formData.bio}
            onChange={(e) => {
              if (e.target.value.length <= 150) {
                setFormData({ ...formData, bio: e.target.value });
              }
            }}
            className="w-full h-40 bg-[#F5FAEB] rounded-xl px-5 py-2.5 text-[#222222] resize-none"
            placeholder="輸入名片簡介"
          />
        </div>
      </div>

      {/* 隱藏的文件輸入 */}
      <input
        type="file"
        ref={avatarInputRef}
        onChange={(e) => handleFileUpload(e, 'avatar')}
        className="hidden"
        accept="image/*"
      />
      <input
        type="file"
        ref={coverPhotoInputRef}
        onChange={(e) => handleFileUpload(e, 'coverPhoto')}
        className="hidden"
        accept="image/*"
      />
      <input
        type="file"
        ref={companyLogoInputRef}
        onChange={(e) => handleFileUpload(e, 'companyLogo')}
        className="hidden"
        accept="image/*"
      />
    </div>
  );
};

export default CardForm;