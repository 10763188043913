import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, Plus, Share2 } from 'lucide-react';
import CollectCardModal from './CollectCardModal';
import { useAuth } from '../context/AuthContext';
import { useCards } from '../context/CardContext';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const { isNewUser } = useCards();

  const [isCollectModalOpen, setIsCollectModalOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState('數位名片系統');

  useEffect(() => {
    updatePageTitle();
  }, [location.pathname, user, isNewUser]);

  const updatePageTitle = () => {
    let path = location.pathname;
    if (user?.userName) {
      path = path.replace(`/${user.userName}`, '');
    }
    
    if (path === '/' || path === '') {
      setPageTitle(isNewUser ? '歡迎使用' : '我的名片');
    } else {
      switch (path) {
        case '/contacts':
          setPageTitle('名片夾');
          break;
        case '/scan':
          setPageTitle('掃描收藏');
          break;
        case '/analysis':
          setPageTitle('數據分析(開發中，敬請期待)');
          break;
        case '/settings':
          setPageTitle('帳戶設定');
          break;
        default:
          setPageTitle('數位名片系統');
      }
    }
  };

  const handleAddCard = () => {
    if (user?.userName) {
      navigate(`/${user.userName}/create-card`);
    }
  };

  const renderRightButton = () => {
    if (!isAuthenticated()) {
      return null;
    }

    if (isNewUser) {
      return (
        <button 
          className="flex items-center justify-center px-4 py-2 bg-[#398378] text-white rounded-full text-base w-[125px] h-[40px]"
          onClick={handleAddCard}
        >
          <span className="font-normal text-[16px] leading-[150%] tracking-[0.02em]">+ 新增名片</span>
        </button>
      );
    }

    switch (pageTitle) {
      case '我的名片':
        return (
          <button 
            className="flex items-center justify-center px-4 py-2 bg-[#398378] text-white rounded-full text-base w-[125px] h-[40px]"
            onClick={handleAddCard}
          >
            <span className="font-normal text-[16px] leading-[150%] tracking-[0.02em]">+ 新增名片</span>
          </button>
        );
      case '名片夾':
        return (
          <button 
            className="px-4 py-2 bg-[#398378] text-white rounded-full text-[16px] leading-[150%] tracking-[0.02em] w-[125px] h-[40px]"
            onClick={() => setIsCollectModalOpen(true)}
          >
            收藏名片
          </button>
        );
      default:
        return null;
    }
  };

  if (!isAuthenticated()) {
    return null;
  }

  return (
    <header className="bg-white shadow-md fixed top-0 left-0 right-0 z-10">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <h1 className="text-[24px] font-black text-[#222222] font-['Noto_Serif_TC'] leading-[150%] tracking-[0.02em]">
          {pageTitle}
        </h1>
        <div className="flex items-center space-x-4">
          {renderRightButton()}
        </div>
      </div>
      {isAuthenticated() && (
        <CollectCardModal 
          isOpen={isCollectModalOpen} 
          onClose={() => setIsCollectModalOpen(false)} 
        />
      )}
    </header>
  );
};

export default Header;