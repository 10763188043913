import React, { useState, useEffect } from 'react';
import { fetchAllActivities, addNewActivity } from '../api/contactApi';

const ActivityPurposeInput = ({ value, onChange }) => {
  const [activities, setActivities] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    fetchAllActivities().then(setActivities);
  }, []);

  useEffect(() => {
    const activity = activities.find(a => a.aid.toString() === value.toString());
    setSelectedActivity(activity);
    setInputValue(activity ? activity.aName : '');
  }, [value, activities]);

  const handleFocus = () => setShowOptions(true);
  const handleBlur = () => setTimeout(() => setShowOptions(false), 200);

  const handleSelectOption = (activity) => {
    onChange(activity.aid.toString());
    setInputValue(activity.aName);
    setSelectedActivity(activity);
    setShowOptions(false);
  };

  const handleAddNewActivity = async () => {
    if (inputValue && !activities.some(a => a.aName.toLowerCase() === inputValue.toLowerCase())) {
      setIsLoading(true);
      try {
        const newActivity = await addNewActivity(inputValue);
        const updatedActivities = await fetchAllActivities();
        setActivities(updatedActivities);
        handleSelectOption(newActivity);
      } catch (error) {
        console.error('Failed to add new activity:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="relative w-full">
      <div className="flex items-center w-full px-5 py-2.5 bg-[#F5FAEB] border border-[#AAB979] rounded-full">
        {selectedActivity && (
          <div 
            className="w-4 h-4 rounded mr-2" 
            style={{ backgroundColor: selectedActivity.color }}
          ></div>
        )}
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="flex-grow bg-transparent text-[#222222] placeholder-[#CCD5AE] focus:outline-none"
          placeholder="輸入活動目的"
        />
      </div>
      {showOptions && (
        <div className="absolute left-0 top-[52px] w-[335px] bg-white shadow-[0px_4px_12px_rgba(0,0,0,0.15)] rounded-xl overflow-hidden">
          <div className="flex flex-col items-start py-2">
            <div 
              className="flex items-center px-5 py-2.5 w-full hover:bg-[#F5FAEB] cursor-pointer" 
              onClick={() => handleSelectOption({ aid: '0', aName: '未分類', color: '#A1A1A1' })}
            >
              <div className="w-4 h-4 bg-[#A1A1A1] rounded mr-2"></div>
              <span className="text-[#222222] text-base">未分類</span>
            </div>
            {activities.map((activity) => (
              <div 
                key={activity.aid} 
                className="flex items-center px-5 py-2.5 w-full hover:bg-[#F5FAEB] cursor-pointer"
                onClick={() => handleSelectOption(activity)}
              >
                <div className="w-4 h-4 rounded mr-2" style={{ backgroundColor: activity.color }}></div>
                <span className="text-[#222222] text-base">{activity.aName}</span>
              </div>
            ))}
            {inputValue && !activities.some(a => a.aName.toLowerCase() === inputValue.toLowerCase()) && inputValue !== '未分類' && (
              <div 
                className="flex items-center px-5 py-2.5 w-full hover:bg-[#F5FAEB] cursor-pointer"
                onClick={handleAddNewActivity}
              >
                <span className="text-[#398378] text-base mr-2">新增：</span>
                <div className="w-4 h-4 bg-[#E9770C] rounded mr-2"></div>
                <span className="text-[#222222] text-base">{inputValue}</span>
              </div>
            )}
          </div>
        </div>
      )}
      {isLoading && <div className="absolute top-full left-0 w-full mt-2 text-center">正在添加...</div>}
    </div>
  );
};

export default ActivityPurposeInput;