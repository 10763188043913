import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import ExchangeTrendChart from '../components/ExchangeTrendChart';
import ActivityRecord from '../components/ActivityRecord';

const DonutChart = ({ 
  data, 
  colors, 
  size = 180, 
  strokeWidth = 8,
  centerText,
  centerSubtext,
  centerTextColor = "#BB7A3A",
  centerSubtextColor = "#222222"
}) => {
  const total = data.reduce((sum, item) => sum + item.value, 0);
  const radius = 50 - strokeWidth / 2;

  const createArcPath = (startAngle, endAngle) => {
    const start = polarToCartesian(50, 50, radius, endAngle);
    const end = polarToCartesian(50, 50, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    return [
      "M", start.x, start.y,
      "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
  };

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  };

  let startAngle = 0;

  return (
    <div className={`relative w-[${size}px] h-[${size}px]`}>
      <svg className="w-full h-full" viewBox="0 0 100 100">
        {total > 0 ? (
          data.map((item, index) => {
            const endAngle = startAngle + (item.value / total) * 360;
            const path = createArcPath(startAngle, endAngle);
            startAngle = endAngle;
            return (
              <path 
                key={index} 
                d={path} 
                fill="none"
                stroke={colors[index % colors.length]} 
                strokeWidth={strokeWidth}
              />
            );
          })
        ) : (
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="none"
            stroke={colors[0]}
            strokeWidth={strokeWidth}
          />
        )}
      </svg>
      <div className="absolute inset-0 flex flex-col justify-center items-center">
        <span className="text-2xl font-black" style={{ color: centerTextColor }}>
          {total.toLocaleString()} {centerText}
        </span>
        <span className="text-base" style={{ color: centerSubtextColor }}>
          {centerSubtext}
        </span>
      </div>
    </div>
  );
};

const DataAnalysis = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('monthly');

  const viewData = [
    { name: '@shiaomei_xyz', value: 5961 },
    { name: '@shiaomei_abc', value: 4173 },
    { name: '@pretty_fashion', value: 1192 },
    { name: '其他', value: 5 },
  ];

  const collectData = [
    { name: '科技', value: 60 },
    { name: '設計', value: 40 },
    { name: '金融', value: 2 },
    { name: '其他', value: 34 },
  ];

  const collectedData = [
    { date: '4/1', count: 1 },
    { date: '4/8', count: 2 },
    { date: '4/15', count: 0 },
    { date: '4/22', count: 3 },
    { date: '4/29', count: 1 },
  ];

  const beingCollectedData = [
    { date: '4/1', count: 2 },
    { date: '4/8', count: 1 },
    { date: '4/15', count: 3 },
    { date: '4/22', count: 2 },
    { date: '4/29', count: 1 },
  ];

  const viewColors = ['#BB7A3A', '#D4A373', '#FCE3CA', '#FFF7EB'];
  const collectColors = ['#398378', '#AAB979', '#CCD5AE', '#F5FAEB'];

  const viewTotal = viewData.reduce((sum, item) => sum + item.value, 0);
  const collectTotal = collectData.reduce((sum, item) => sum + item.value, 0);

  return (
    <div className="flex flex-col items-start p-5 gap-3 w-full bg-white">
      {/* 我的名片 */}
      <div className="flex items-center gap-2 w-full">
        <div className="w-0.5 h-[30px] bg-gradient-to-b from-[#AAB979] to-[#EFD758] rounded-full"></div>
        <h2 className="text-xl font-semibold text-[#222222]">追蹤我的名片</h2>
      </div>

      <div className="flex justify-center items-center gap-5 w-full">
        <div className="flex flex-col items-start p-2 gap-1 w-[114px] h-[84px] bg-[#FCE3CA] rounded-3xl">
          <div className="flex justify-center items-center p-1 gap-2.5 w-[98px] h-8 bg-white rounded-full">
            <span className="text-base font-medium text-[#BB7A3A]">被觀看次數</span>
          </div>
          <div className="flex justify-center items-center p-1 gap-2.5 w-[98px] h-8 rounded-full">
            <span className="text-base text-[#D4A373]">被收藏次數</span>
          </div>
        </div>

        <DonutChart 
          data={viewData} 
          colors={viewColors} 
          centerText="次"
          centerSubtext="總點擊數"
          centerTextColor="#BB7A3A"
          centerSubtextColor="#222222"
        />
      </div>

      <div className="w-full">
        {viewData.map((item, index) => (
          <div key={index} className="flex items-center gap-2 mb-2">
            <div 
              className={`w-4 h-4 rounded ${viewColors[index] === '#FFF7EB' ? 'border border-[#FCE3CA]' : ''}`} 
              style={{backgroundColor: viewColors[index]}}
            ></div>
            <span className="text-lg text-[#222222]">{item.name}</span>
            <span className="text-lg text-[#B3B3B3] flex-grow">{((item.value / viewTotal) * 100).toFixed(0)}%</span>
            <span className="text-lg text-[#222222] text-right">{item.value.toLocaleString()} 次</span>
          </div>
        ))}
      </div>

      <span className="text-base text-[#D4A373] self-end">顯示全部</span>

      {/* 收藏名片 */}
      <div className="flex items-center gap-2 w-full mt-6">
        <div className="w-0.5 h-[30px] bg-gradient-to-b from-[#AAB979] to-[#EFD758] rounded-full"></div>
        <h2 className="text-xl font-semibold text-[#222222]">收藏名片</h2>
      </div>

      <div className="flex justify-center items-center gap-5 w-full">
        <DonutChart 
          data={collectData} 
          colors={collectColors}
          centerText="張"
          centerSubtext="客戶名片"
          centerTextColor="#398378"
          centerSubtextColor="#222222"
        />

        <div className="flex flex-col items-start p-2 gap-1 w-[97px] h-[84px] bg-[#E9F3D1] rounded-3xl">
          <div className="flex justify-center items-center p-1 gap-2.5 w-[81px] h-8 bg-white rounded-full">
            <span className="text-base font-medium text-[#398378]">行業類別</span>
          </div>
          <div className="flex justify-center items-center p-1 gap-2.5 w-[81px] h-8 rounded-full">
            <span className="text-base text-[#AAB979]">客戶屬性</span>
          </div>
        </div>
      </div>

      <div className="w-full">
        {collectData.map((item, index) => (
          <div key={index} className="flex items-center gap-2 mb-2">
            <div 
              className={`w-4 h-4 rounded ${collectColors[index] === '#F5FAEB' ? 'border border-[#CCD5AE]' : ''}`} 
              style={{backgroundColor: collectColors[index]}}
            ></div>
            <span className="text-lg text-[#222222]">{item.name}</span>
            <span className="text-lg text-[#B3B3B3] flex-grow">{((item.value / collectTotal) * 100).toFixed(0)}%</span>
            <span className="text-lg text-[#222222] text-right">{item.value.toLocaleString()} 張</span>
          </div>
        ))}
      </div>

      <span className="text-base text-[#AAB979] self-end">顯示全部</span>

      {/* 名片交換趨勢 */}
      <div className="flex flex-col items-start w-full gap-3">
        <div className="flex items-center gap-2 w-full">
          <div className="w-0.5 h-[30px] bg-gradient-to-b from-[#AAB979] to-[#EFD758] rounded-full"></div>
          <h2 className="text-xl font-semibold text-[#222222]">名片交換趨勢</h2>
        </div>

        <div className="flex items-center w-full">
          <div className="flex-1 flex items-center bg-[#E9F3D1] rounded-full p-2">
            <button
              className={`flex-1 py-2 px-4 rounded-full text-center ${
                selectedPeriod === 'monthly' ? 'bg-white text-[#398378]' : 'text-[#AAB979]'
              }`}
              onClick={() => setSelectedPeriod('monthly')}
            >
              每月
            </button>
            <button
              className={`flex-1 py-2 px-4 rounded-full text-center ${
                selectedPeriod === 'yearly' ? 'bg-white text-[#398378]' : 'text-[#AAB979]'
              }`}
              onClick={() => setSelectedPeriod('yearly')}
            >
              每年
            </button>
          </div>
          <div className="flex items-center ml-4">
            <ChevronLeft className="text-[#AAB979]" />
            <span className="mx-2 text-[#1D443E]">2024 / 04</span>
            <ChevronRight className="text-[#AAB979]" />
          </div>
        </div>

        <div className="flex w-full gap-2">
          <div className="flex-1 bg-white shadow-md rounded-xl p-3">
            <div className="flex items-end mb-2">
              <span className="text-xs text-[#808080]">比上月</span>
              <div className="flex items-center ml-2">
                <ChevronLeft className="w-5 h-5 text-[#AAB979] rotate-180" />
                <span className="text-sm font-semibold text-[#AAB979]">3</span>
              </div>
            </div>
            <div className="text-sm text-[#222222]">本月收藏</div>
            <div className="text-2xl font-black text-[#398378]">12 張</div>
          </div>
          <div className="flex-1 bg-white shadow-md rounded-xl p-3">
            <div className="text-sm text-[#222222]">每月平均收藏</div>
            <div className="text-2xl font-black text-[#398378]">15 張</div>
          </div>
        </div>

        <div className="flex w-full gap-2">
          <div className="flex-1 bg-white shadow-md rounded-xl p-3">
            <div className="flex items-end mb-2">
              <span className="text-xs text-[#808080]">比上月</span>
              <div className="flex items-center ml-2">
                <ChevronLeft className="w-5 h-5 text-[#D4A373] rotate-180" />
                <span className="text-sm font-semibold text-[#D4A373]">3</span>
              </div>
            </div>
            <div className="text-sm text-[#222222]">本月被收藏</div>
            <div className="text-2xl font-black text-[#BB7A3A]">20 張</div>
          </div>
          <div className="flex-1 bg-white shadow-md rounded-xl p-3">
            <div className="text-sm text-[#222222]">每月平均被收藏</div>
            <div className="text-2xl font-black text-[#BB7A3A]">17 張</div>
          </div>
        </div>

        <div className="w-full mt-4">
          <ExchangeTrendChart 
            collectedData={collectedData} 
            beingCollectedData={beingCollectedData} 
          />
        </div>

        <div className="flex flex-col w-full gap-2 mt-4">
          <div className="flex items-center">
            <div className="w-4 h-4 bg-[#AAB979] rounded mr-2"></div>
            <span className="text-lg text-[#222222] flex-grow">本月收藏</span>
            <span className="text-lg text-[#222222]">12 張</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-[#D4A373] rounded mr-2"></div>
            <span className="text-lg text-[#222222] flex-grow">本月被收藏</span>
            <span className="text-lg text-[#222222]">20 張</span>
          </div>
        </div>
      </div>
      
      {/* 活動紀錄 */}
      <ActivityRecord />
    </div>
  );
};

export default DataAnalysis;