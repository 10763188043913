import React, { useState } from 'react';
import { ArrowRight, Camera, Image as ImageIcon, Plus, X, MoreVertical, MapPin, ChevronDown,CreditCard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import CardForm from './CardForm';
import { addCard } from '../api/cardApi';

const CreateCard = () => {
  const [showSettings, setShowSettings] = useState(false);
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [formData, setFormData] = useState({
    avatar: null,
    coverPhoto: null,
    cardId: '@',
    name: '',
    email: '',
    company: '',
    companyLogo: null,
    title: '',
    industry: '',
    companyPhone: {
      areaCode: '+886',
      number: '',
      extension: ''
    },
    personalPhone: {
      areaCode: '+886',
      number: ''
    },
    socialLinks: [],
    bio: ''
  });

  const navigate = useNavigate();
  const { user } = useAuth();

  const handleFormChange = (newFormData) => {
    console.log('Form data updated:', newFormData);
    setFormData(newFormData);
  };

  const handleSave = async () => {
    try {
      const result = await addCard(formData);
      if (result.success) {
        setShowSavedModal(true);
      } else {
        alert('保存失敗: ' + result.message);
      }
    } catch (error) {
      console.error('保存名片時出錯:', error);
      alert('保存名片時出錯: ' + error.message);
    }
  };

  return (
    <div className="bg-white min-h-screen pb-10">
      {/* 設定選單 */}
      {showSettings && <CardSettings onClose={() => setShowSettings(false)} />}
      
      {/* 保存成功模態框 */}
      {showSavedModal && (
        <SavedModal
          onClose={() => setShowSavedModal(false)}
          onContinueEdit={() => setShowSavedModal(false)}
          onViewCard={() => {
            setShowSavedModal(false);
            navigate(`/${user.userName}/`);
          }}
        />
      )}
      
      {/* 固定頭部 */}
      <div className="fixed top-0 left-0 right-0 z-10 bg-white">
        <div className="flex flex-col justify-center items-center px-5 py-2.5 gap-1 h-[82px] border-b border-[#E6E6E6]">
          <div className="flex items-center justify-between w-full h-10">
            <button className="w-[30px] h-[30px] text-[#AAB979] transform rotate-180" onClick={() => navigate(-1)}>
              <ArrowRight size={24} />
            </button>
            <h2 className="text-xl font-semibold text-[#222222] flex-grow text-center">新增名片</h2>
            <div className="flex space-x-2">
              {/* <button className="px-4 py-2 bg-white text-[#398378] border border-[#398378] rounded-full text-base">
                預覽
              </button> */}
              <button onClick={handleSave} className="px-4 py-2 bg-[#398378] text-white rounded-full text-base">
                儲存
              </button>
              <button 
                className="p-2 bg-white bg-opacity-80 rounded-full"
                onClick={() => setShowSettings(true)}
              >
                <MoreVertical size={24} className="text-[#4D4D4D]" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* CardForm */}
      <div className="pt-[0px]">
        <CardForm
          initialData={formData}
          onSubmit={handleFormChange}
        />
      </div>
    </div>
  );
};

const Toggle = () => (
  <div className="w-12 h-6 bg-[#F2F2F2] rounded-full relative shadow-inner">
    <div className="absolute w-5 h-5 bg-white rounded-full shadow left-0.5 top-0.5"></div>
  </div>
);

const SettingItem = ({ title, description, icon, toggle, textColor = "text-[#222222]" }) => (
  <div className="flex items-center p-5 border-b border-white">
    {icon && <div className="w-8 mr-3">{icon}</div>}
    <div className="flex-grow">
      <div className="flex justify-between items-center">
        <span className={`${textColor} text-base`}>{title}</span>
        {toggle && <Toggle />}
      </div>
      {description && <p className="text-sm text-[#808080] mt-1">{description}</p>}
    </div>
  </div>
);

const CardSettings = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-end justify-center">
      <div className="bg-white w-[375px] h-[600px] rounded-t-xl shadow-lg flex flex-col animate-slide-up">
        <div className="flex justify-between items-center p-5 border-b border-[#CCD5AE]">
          <h2 className="text-xl font-semibold text-[#222222]">設定</h2>
          <button onClick={onClose}>
            <X size={24} className="text-[#808080]" />
          </button>
        </div>
        <div className="flex-grow overflow-y-auto">
          <SettingItem 
            title="掃描名片編輯" 
            icon={<CreditCard size={24} className="text-[#398378]" />}
          />
          <SettingItem 
            title="公開被收藏次數" 
            description="其他使用者將在你的名片上檢視到你的名片被其他使用者收藏的次數"
            toggle
          />
          <SettingItem 
            title="隱藏名片" 
            description="其他使用者將無法查詢到你的名片"
            toggle
          />
          <SettingItem 
            title="刪除名片" 
            description="其他使用者收藏前，你可以刪除此名片"
            textColor="text-[#F65757]"
          />
        </div>
        <div className="p-5">
          <button className="w-full bg-[#398378] text-white py-2 rounded-full">
            儲存
          </button>
        </div>
      </div>
    </div>
  );
};

const SavedModal = ({ onClose, onContinueEdit, onViewCard }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
    <div className="relative w-[335px] h-[270px] bg-white rounded-[10px] shadow-[0px_4px_12px_rgba(0,0,0,0.15)] flex flex-col items-center p-5 overflow-hidden">
      {/* 背景倒三角形 */}
      <div className="absolute w-[507px] h-[121px] left-[-86px] top-0 bg-[#E9F3D1] z-0"
           style={{
             clipPath: 'polygon(50% 100%, 0 0, 100% 0)',
           }}
      ></div>
      
      {/* 新增成功圖示 */}
      <div className="w-[118px] h-[120px] relative z-10 mb-2">
        {/* Ellipse 6 */}
        <div className="absolute inset-x-0 top-[2.86%] bottom-0 bg-[#F5FAEB] rounded-full"></div>
        
        {/* Subtract 1 */}
        <div className="absolute left-[19.92%] right-[19.92%] top-[42.5%] bottom-[25.83%] bg-[#F8E6B9]"></div>
        
        {/* Subtract 2 */}
        <div className="absolute left-[25.85%] right-[25.85%] top-[27.5%] bottom-[50%] bg-[#CCD5AE]"></div>
        
        {/* Vector (打勾) */}
        <div className="absolute left-[66.74%] right-[10.47%] top-0 bottom-[77.86%]"
             style={{
               background: 'linear-gradient(180deg, #4DBC66 0%, #CED141 100%)',
               clipPath: 'path("M3.5 7C3.5 7 5 8.5 7.5 11L14.5 3.5")', // 簡單的打勾路徑
               transform: 'scale(2)',
             }}
        ></div>
        
        {/* Rectangle 12 */}
        <div className="absolute left-[33.94%] right-[61.31%] top-[88.57%] bottom-[6.81%] bg-[#AAB979]"></div>
        
        {/* Rectangle 13 */}
        <div className="absolute left-[7.01%] right-[90.72%] top-[39.34%] bottom-[58.46%] bg-[#CCD5AE]"></div>
        
        {/* Rectangle 16 */}
        <div className="absolute left-[28.96%] right-[68.78%] top-[85.27%] bottom-[12.53%] bg-[#398378]"></div>
        
        {/* Rectangle 14 */}
        <div className="absolute left-[73.53%] right-[24.21%] top-[84.18%] bottom-[13.63%] bg-[#CCD5AE]"></div>
        
        {/* Rectangle 15 */}
        <div className="absolute left-[90.27%] right-[6.56%] top-[63.3%] bottom-[33.63%] bg-[#398378]"></div>
      </div>
      
      {/* 標題 */}

      <h2 className="text-[20px] font-black text-[#398378] tracking-[2px] mb-8 font-['Noto_Serif_TC']">已儲存名片</h2>

      {/* 按鈕組 */}
      <div className="flex gap-2 w-full">
        <button 
          onClick={onContinueEdit} 
          className="flex-none w-[97px] h-[40px] border border-[#398378] text-[#398378] rounded-full text-base flex items-center justify-center"
        >
          繼續編輯
        </button>
        <button 
          onClick={onViewCard} 
          className="flex-grow h-[40px] bg-[#398378] text-white rounded-full text-base flex items-center justify-center"
        >
          檢視名片
        </button>
      </div>
    </div>
  </div>
);

export default CreateCard;