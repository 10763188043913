import React, { useState } from 'react';
import { X, Camera } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ScanCard = () => {
  const [scanMode, setScanMode] = useState('physical');
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1); // 返回上一頁
  };

  const getTitle = () => {
    return scanMode === 'physical' ? '掃描實體名片' : '掃描 QR Code';
  };

  return (
    <div className="fixed inset-0 bg-black text-white flex flex-col z-50">
      {/* Header */}
      <div className="flex justify-between items-center p-4 pt-safe">
        <h1 className="text-lg font-semibold">{getTitle()}</h1>
        <button className="text-white" onClick={handleClose}>
          <X size={24} />
        </button>
      </div>

      {/* Camera View */}
      <div className="flex-1 relative flex flex-col justify-center items-center">
        {/* This would be replaced with actual camera feed */}
        <div className="absolute inset-0 bg-gray-800"></div>
        
        {/* Scan Frame */}
        <div className="relative w-4/5 aspect-[4/3] mb-12">
          <div className="absolute inset-0 border-2 border-white rounded-lg">
            <div className="w-4 h-4 border-t-2 border-l-2 border-white absolute top-0 left-0"></div>
            <div className="w-4 h-4 border-t-2 border-r-2 border-white absolute top-0 right-0"></div>
            <div className="w-4 h-4 border-b-2 border-l-2 border-white absolute bottom-0 left-0"></div>
            <div className="w-4 h-4 border-b-2 border-r-2 border-white absolute bottom-0 right-0"></div>
          </div>
        </div>

        {/* Capture Button */}
        <button className="bg-white rounded-full p-4 mt-4">
          <Camera size={32} className="text-black" />
        </button>
      </div>

      {/* Footer */}
      <div className="flex justify-center items-center space-x-8 p-6 pb-safe">
        <button 
          className={`py-3 px-6 rounded-full ${
            scanMode === 'physical' 
              ? 'bg-white text-black' 
              : 'bg-gray-800 text-white'
          }`}
          onClick={() => setScanMode('physical')}
        >
          實體名片
        </button>
        <button 
          className={`py-3 px-6 rounded-full ${
            scanMode === 'qr' 
              ? 'bg-white text-black' 
              : 'bg-gray-800 text-white'
          }`}
          onClick={() => setScanMode('qr')}
        >
          QR Code
        </button>
      </div>
    </div>
  );
};

export default ScanCard;