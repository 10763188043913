import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const IntroPage = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const autoPlayRef = useRef();

  const slides = [
    {
      image: '/images/intro-1.png',
      title: '電子名片打破傳統，環保高效'
    },
    {
      image: '/images/intro-2.png',
      title: '智慧管理，提升業績'
    },
    {
      image: '/images/intro-3.png',
      title: '數據分析，找出商機'
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 75) {
      nextSlide();
    }
    if (touchStart - touchEnd < -75) {
      setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    }
  };

  useEffect(() => {
    const play = () => {
      nextSlide();
    };
    autoPlayRef.current = play;
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoPlayRef.current) {
        autoPlayRef.current();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-start p-10 gap-6 w-full min-h-screen bg-gradient-to-b from-[#E9F3D1] to-white">
      {/* Logo */}
      <div className="flex justify-center items-center w-full gap-3">
        <img src="/images/stanec-logo.png" alt="Stanec" className="w-full mt-10" />
      </div>

      {/* Title */}
      <h1 className="text-2xl font-black text-[#D4A373] text-center w-full font-['Noto_Serif_TC']">
        全方位名片管理<br />
        Stanec 助您開創更多商機
      </h1>

      {/* Slider */}
      <div 
        className="relative w-full h-[339px] overflow-hidden"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div 
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div key={index} className="w-full flex-shrink-0">
              <img src={slide.image} alt={slide.title} className="w-full h-[279px] object-cover" />
              <p className="text-xl font-semibold text-[#398378] text-center mt-2">{slide.title}</p>
            </div>
          ))}
        </div>
        
        {/* Navigation dots */}
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-5">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`w-2.5 h-2.5 rounded-full cursor-pointer ${
                index === currentSlide ? 'bg-[#398378]' : 'bg-[#E6E6E6]'
              }`}
              onClick={() => setCurrentSlide(index)}
            ></div>
          ))}
        </div>
      </div>

      {/* Buttons */}
      <button 
        className="w-full py-2 bg-[#398378] text-white rounded-full"
        onClick={() => navigate('/register')}
      >
        立即註冊
      </button>
      <button 
        className="w-full py-2 border border-[#398378] text-[#398378] rounded-full"
        onClick={() => navigate('/login')}
      >
        前往登入
      </button>
    </div>
  );
};

export default IntroPage;