import axios from 'axios';
import { User } from 'lucide-react';

const BASE_URL = 'https://api.antqtech.com/Stanec';

export const fetchAllContacts = async () => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/SelAllContact`,
      '',
      {
        headers: {
          'Authorization': token,
          'accept': '*/*'
        }
      }
    );

    if (response.data[0].state === "OK") {
      console.log(response.data[0].userContactList)
      return response.data[0].userContactList;
    } else {
      throw new Error(response.data.msg || '獲取聯絡人失敗');
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // 當狀態碼為 400 時，返回空數組
      return [];
    }
    // 對於其他錯誤，繼續拋出
    throw error;
  }
};

export const fetchContactsDetail = async (cid, ucid) => {
  try {
    const token = localStorage.getItem('jwtToken');
    console.log("C",cid,"U",ucid)
    const response = await axios.post(
      `${BASE_URL}/Card/SelCard`,
      { cid, ucid },
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      console.log("data",response.data)
      return response.data[0];
    } else {
      throw new Error(response.data.msg || '獲取聯絡人詳情失敗');
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return null;
    }
    throw error;
  }
};

// 搜尋會用到 (加入)
export const addContact = async (cid) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const contactData = {
      cid,
      cName: "",
      cEmail: "",
      cCompany_Name: "",
      cJob_Title: "",
      indID: 0,
      tNationID: "",
      cTel: "",
      mNationID: "",
      cMobile: "",
      cAddress: "",
      linkList: [],
      cDescription: "",
      profilePhotoBase64: "",
      extension_pp: ""
    };

    const response = await axios.post(
      `${BASE_URL}/Card/AddContact`,
      contactData,
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return {
        success: true,
        data: response.data[0],
        message: '聯絡人已成功添加'
      };
    } else {
      throw new Error(response.data[0].msg || '添加聯絡人失敗');
    }
  } catch (error) {
    console.error('Error adding contact:', error);
    return {
      success: false,
      error: error.message || '添加聯絡人時發生錯誤'
    };
  }
};

// 搜尋本人
export const searchCardById = async (cid) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/SelCard`,
      { cid, ucid: "string" },  // 使用固定的 ucid 值
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      console.log(response.data[0])
      return response.data[0];

    } else {
      throw new Error(response.data[0].msg || '搜尋失敗');
    }
  } catch (error) {
    console.error("搜尋名片失敗:", error);
    throw error;
  }
};

export const fetchCustomerAttributes = async () => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/SelAllRelation`,
      '',
      {
        headers: {
          'Authorization': token,
          'accept': '*/*'
        }
      }
    );

    if (response.data[0].state === "OK") {
      const attributes = response.data[0].relationList || [];
      // 確保 "未分類" 總是在列表中
      if (!attributes.some(attr => attr.rName === "未分類")) {
        attributes.unshift({ rid: 0, rName: "未分類" });
      }
      console.log(attributes)
      return attributes;
    } else {
      throw new Error(response.data[0].msg || '獲取客戶屬性失敗');
    }
  } catch (error) {
    console.error('Error fetching customer attributes:', error);
    // 如果發生錯誤，至少返回 "未分類"
    return [{ rid: 0, rName: "未分類" }];
  }
};

export const addCustomerAttribute = async (rName) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/AddRelation`,
      { rName },
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return {
        success: true,
        data: response.data[0]
      };
    } else {
      throw new Error(response.data[0].msg || '添加客戶屬性失敗');
    }
  } catch (error) {
    console.error('Error adding customer attribute:', error);
    return {
      success: false,
      error: error.message || '添加客戶屬性時發生錯誤'
    };
  }
};

export const deleteCustomerAttribute = async (rid) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/DelRelation`,
      { rid },
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return {
        success: true,
        data: response.data[0]
      };
    } else {
      throw new Error(response.data[0].msg || '刪除客戶屬性失敗');
    }
  } catch (error) {
    console.error('Error deleting customer attribute:', error);
    return {
      success: false,
      error: error.message || '刪除客戶屬性時發生錯誤'
    };
  }
};

// 活動相關
export const fetchAllActivities = async () => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/SelAllActivity`,
      '',
      {
        headers: {
          'Authorization': token,
          'accept': '*/*'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return response.data[0].activityList;
    } else {
      throw new Error(response.data[0].msg || '獲取活動列表失敗');
    }
  } catch (error) {
    console.error('Error fetching activities:', error);
    return [];
  }
};

export const addNewActivity = async (aName) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/AddActivity`,
      { aName },
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return response.data[0];
    } else {
      throw new Error(response.data[0].msg || '添加活動失敗');
    }
  } catch (error) {
    console.error('Error adding activity:', error);
    throw error;
  }
};


export const updateCardRelation = async (ucidList, rid) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/UpdCardRel`,
      { ucidList, rid },
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return { success: true, data: response.data[0] };
    } else {
      throw new Error(response.data[0].msg || '更新卡片關係失敗');
    }
  } catch (error) {
    console.error('Error updating card relation:', error);
    return {
      success: false,
      error: error.message || '更新卡片關係時發生錯誤'
    };
  }
};

export const removeContact = async (ucid) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/DelContact`,
      { ucid },
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return {
        success: true,
        message: '聯絡人已成功移除'
      };
    } else {
      throw new Error(response.data[0].msg || '移除聯絡人失敗');
    }
  } catch (error) {
    console.error('Error removing contact:', error);
    return {
      success: false,
      error: error.message || '移除聯絡人時發生錯誤'
    };
  }
};

// 活動組
export const addInteraction = async (interactionData) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/AddInteraction`,
      interactionData,
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return {
        success: true,
        data: response.data[0],
        message: '互動記錄已成功添加'
      };
    } else {
      throw new Error(response.data[0].msg || '添加互動記錄失敗');
    }
  } catch (error) {
    console.error('Error adding interaction:', error);
    return {
      success: false,
      error: error.message || '添加互動記錄時發生錯誤'
    };
  }
};

export const fetchAllInteractions = async (cid) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/SelAllInteraction`,
      { cid },
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return {
        success: true,
        data: response.data[0].interactionList,
        message: '成功獲取所有互動記錄'
      };
    } else {
      throw new Error(response.data[0].msg || '獲取互動記錄失敗');
    }
  } catch (error) {
    console.error('Error fetching interactions:', error);
    return {
      success: false,
      error: error.message || '獲取互動記錄時發生錯誤'
    };
  }
};