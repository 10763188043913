import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserCircle, Lock, ChevronRight } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

const Settings = () => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="flex flex-col h-[calc(100vh-70px)] bg-white overflow-y-auto">
      {/* User Info */}
      <div className="flex items-center w-full p-5 gap-2.5 h-[100px] border-b border-[#E6E6E6] mt-14">
        <div className="w-[60px] h-[60px] bg-[#E9F3D1] rounded-full border-2 border-white flex items-center justify-center flex-shrink-0">
          <span className="text-2xl font-semibold text-[#398378]">
            {user?.userName?.charAt(0).toUpperCase() || ''}
          </span>
        </div>
        <div className="flex flex-col justify-center gap-1 flex-grow">
          <h2 className="text-lg font-semibold text-[#222222] leading-[150%] tracking-[0.02em]">
            {user?.userName || ''}
          </h2>
          <p className="text-sm text-[#398378] leading-[150%] tracking-[0.02em]">
            {user?.email || ''}
          </p>
        </div>
      </div>

      {/* Menu Items */}
      <div className="w-full">
        {/* <MenuItem 
          icon={<UserCircle size={30} className="text-[#AAB979]" />} 
          text="編輯帳戶資料" 
        />
        <MenuItem 
          icon={<Lock size={30} className="text-[#AAB979]" />} 
          text="變更密碼" 
        /> */}
        <MenuItem 
          text="登出" 
          textColor="text-[#808080]" 
          hideArrow 
          onClick={handleLogout}
        />
      </div>

      {/* Spacer */}
      <div className="flex-grow" />

      {/* App Version */}
      <div className="flex justify-center items-center w-full p-3 border-t border-[#E6E6E6]">
        <div className="flex items-center">
          <span className="text-[#398378] font-semibold mr-2">stanec</span>
        </div>
        <span className="text-sm text-[#808080]">ver 1.0</span>
      </div>
    </div>
  );
};

const MenuItem = ({ icon, text, textColor = "text-[#222222]", hideArrow = false, onClick }) => (
  <div 
    className="flex items-center w-full px-5 py-3 border-b border-white cursor-pointer"
    onClick={onClick}
  >
    {icon && <div className="w-[30px] h-[30px] mr-2.5">{icon}</div>}
    <span className={`flex-grow text-base leading-[150%] tracking-[0.02em] ${textColor}`}>{text}</span>
    {!hideArrow && <ChevronRight className="text-[#AAB979]" />}
  </div>
);

export default Settings;