import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, Share2, QrCode, Edit, MoreVertical, Mail, Phone, MapPin, Instagram, Youtube, Link, Globe, MessageCircle, ArrowRight, ChevronDown, Edit3 } from 'lucide-react';
import AddActivityForm from '../components/AddActivityForm';
import { fetchContactsDetail, addContact, removeContact } from '../api/contactApi';
import ActivitySection from '../components/ActivitySection';
import CustomerAttributeModal from '../components/CustomerAttributeModal';
import { industryCodeMap } from '../data/carddata';

const CustomerCardDetail = () => {
  const [showAddActivity, setShowAddActivity] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [isCollected, setIsCollected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [showAttributeModal, setShowAttributeModal] = useState(false);
  const [customerAttribute, setCustomerAttribute] = useState('未分類');

  const socialIcons = {
    1: { icon: Instagram, name: 'Instagram' },
    2: { icon: Youtube, name: 'Youtube' },
    3: { icon: MessageCircle, name: 'Discord' },
    4: { icon: Globe, name: 'Website' },
    5: { icon: MessageCircle, name: 'Line' }
  };

  const fetchData = async () => {
    try {
      const pathParts = location.pathname.split('/');
      const cid = pathParts[pathParts.length - 1];
      const data = await fetchContactsDetail(cid, "string");
      console.log(data);
      setCustomerData(data);
      setIsCollected(data.cSaved_Status === 1);
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  const handleCollect = async () => {
    setIsLoading(true);
    try {
      if (isCollected) {
        const result = await removeContact(customerData.ucid+"");
        if (result.success) {
          setIsCollected(false);
        } else {
          alert("取消收藏失敗：" + result.error);
        }
      } else {
        const result = await addContact(customerData.cid);
        if (result.success) {
          setIsCollected(true);
        } else {
          alert("收藏失敗：" + result.error);
        }
      }
      // 重新獲取數據以更新UI
      await fetchData();
    } catch (error) {
      console.error("Error collecting/uncollecting contact:", error);
      alert("操作時發生錯誤");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!customerData) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  const industryId = parseInt(customerData?.indID, 10);
  const industryName = !isNaN(industryId) 
    ? (industryCodeMap[industryId] || '未知行業')
    : '未知行業';

  return (
    <div className="bg-white min-h-screen pb-20 w-full">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-white z-20">
        <div className="flex flex-col justify-center items-center px-5 py-2.5 gap-1 w-[375px] h-[82px] border-b border-[#E6E6E6]">
          <div className="flex flex-row items-center justify-between w-full h-10">
            <button 
              onClick={handleGoBack}
              className="w-[30px] h-[30px] text-[#AAB979] transform rotate-180">
              <ArrowRight size={24} />
            </button>
            <h2 className="text-xl font-semibold text-[#222222] flex-grow text-center">{customerData.cName}</h2>
            <div className="flex space-x-2">
              {!isCollected && (
                <button 
                onClick={handleCollect}
                className="w-10 h-10 flex items-center justify-center bg-white bg-opacity-80 rounded-full">
                  <Edit size={24} className="text-[#398378]" />
                </button>
              )}
              <button className="w-10 h-10 flex items-center justify-center bg-white bg-opacity-80 rounded-full">
                <Share2 size={24} className="text-[#398378]" />
              </button>
            </div>
          </div>
          <div className="w-full text-sm text-[#808080]">@{customerData.cid}</div>
        </div>
      </header>

      {/* Background and Avatar */}
      <div 
        className="relative h-[100px] bg-gradient-to-r from-blue-400 to-blue-500 mt-20"
        style={customerData.bpFileName ? {backgroundImage: `url(https://api.antqtech.com/stanec/photo/BackgroundPhoto/${customerData.bpFileName})`, backgroundSize: 'cover'} : {}}
      >
        {/* <button className="absolute top-5 right-5 w-10 h-10 bg-white bg-opacity-80 rounded-full flex items-center justify-center">
          <MoreVertical size={24} className="text-[#4D4D4D]" />
        </button> */}
      </div>

      {/* Avatar and Collect Button */}
      <div className="px-5 -mt-12 flex justify-between items-end">
        <div className="w-[98px] h-[98px] rounded-full bg-gradient-to-br from-[#D1F1F3] to-[#95DC8A] border-2 border-white overflow-hidden z-10">
          <img src={`https://api.antqtech.com/stanec/photo/profilePhoto/${customerData.ppFileName}`} alt="Avatar" className="w-full h-full object-cover" />
        </div>
        <button 
          onClick={handleCollect}
          disabled={isLoading}
          className={`flex flex-row justify-center items-center p-[8px_16px] gap-[4px] w-[114px] h-[37px] rounded-[100px] z-5 ${
            isCollected 
              ? 'bg-white border border-[#AAB979] text-[#AAB979]'
              : isLoading
                ? 'bg-[#398378] text-white opacity-50'
                : 'bg-[#398378] text-white'
          }`}
        >
          {isCollected ? (
            <span className="font-['Noto_Sans_TC'] font-normal text-[13px] leading-[150%] tracking-[0.02em]">已收藏名片</span>
          ) : (
            <>
              <QrCode size={21} />
              <span className="font-['Noto_Sans_TC'] font-normal text-[13px] leading-[150%] tracking-[0.02em]">
                {isLoading ? '收藏中...' : '收藏名片'}
              </span>
            </>
          )}
        </button>
      </div>

      {/* New buttons for collected contacts */}
      {isCollected && (
        <div className="flex flex-row items-center px-5 py-2 gap-2 w-full">
          <button 
            onClick={() => setShowAttributeModal(true)}
            className={`flex items-center justify-center px-4 py-2 gap-1 h-[37px] rounded-full bg-white
              ${customerAttribute === '未分類' 
                ? 'border border-[#808080]' 
                : 'border border-[#398378]'}`}
          >
            <span className={`text-sm ${customerAttribute === '未分類' ? 'text-[#808080]' : 'text-[#398378]'}`}>
              {customerAttribute}
            </span>
            <ChevronDown size={21} className={customerAttribute === '未分類' ? 'text-[#808080]' : 'text-[#398378]'} />
          </button>
          {/* <button className="flex items-center justify-center px-4 py-2 gap-1 h-[37px] border border-[#808080] rounded-full">
            <span className="text-sm text-[#808080]">備註</span>
            <Edit3 size={21} className="text-[#808080]" />
          </button> */}
        </div>
      )}

      {/* User Info */}
      <div className="px-5 pt-4">
        <h2 className="text-2xl font-black text-[#222222] font-['Noto_Serif_TC']">{customerData.cName}</h2>
        <p className="text-base text-[#398378] mt-1">{customerData.cid}</p>
        <p className="text-base text-[#222222] mt-1">{customerData.cCompany_Name} · {customerData.cJob_Title}</p>
        <div className="flex items-center mt-3 text-sm text-[#AAB979]">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
            <path d="M4.375 17.5V5.25C4.375 4.42157 5.04657 3.75 5.875 3.75H15.125C15.9534 3.75 16.625 4.42157 16.625 5.25V17.5L10.5 14L4.375 17.5Z" fill="url(#paint0_linear_1721_25167)"/>
            <defs>
              <linearGradient id="paint0_linear_1721_25167" x1="10.5" y1="3.75" x2="10.5" y2="17.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#398378"/>
                <stop offset="1" stopColor="#EFD758"/>
              </linearGradient>
            </defs>
          </svg>
          <span>已有 {customerData.cSave_Count} 人收藏此名片</span>
        </div>

        {/* Social Links */}
        <div className="flex mt-5 space-x-4">
          {customerData.linkList.map((link) => {
            const SocialIcon = socialIcons[link.link_ID]?.icon || Link;
            return (
              <a 
                key={link.link_ID} 
                href={link.link_Url} 
                className="w-9 h-9 bg-[#398378] rounded-full flex items-center justify-center"
                title={socialIcons[link.link_ID]?.name || 'Social Link'}
              >
                <SocialIcon className="text-white w-5 h-5" />
              </a>
            );
          })}
        </div>

        {/* Bio */}
        <div className="mt-5 flex">
          <svg className="flex-shrink-0 w-5 h-5 text-[#AAB979] mr-2" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.875 8.75H14.875M7.875 12.25H14.875M17.5 3.5H4.375C3.54657 3.5 2.875 4.17157 2.875 5V16C2.875 16.8284 3.54657 17.5 4.375 17.5H17.5C18.3284 17.5 19 16.8284 19 16V5C19 4.17157 18.3284 3.5 17.5 3.5Z" stroke="#AAB979" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className="text-sm text-[#222222]">{customerData.cDescription}</p>
        </div>
      </div>

      {/* Divider */}
      <div className="h-3 bg-[#F5FAEB] my-3"></div>

      {/* Activity Section */}
      {isCollected && <ActivitySection />}
      {isCollected &&  <div className="h-3 bg-[#F5FAEB] my-3"></div>}

      {/* Contact Info */}
      <div className="px-5">
        <h3 className="text-xl font-semibold flex items-center mb-5">
          <div className="w-0.5 h-[30px] bg-gradient-to-b from-[#AAB979] to-[#EFD758] mr-2"></div>
          聯絡資訊
        </h3>

        <div className="flex items-start mb-4">
        <div className="w-[60px] h-[60px] bg-gradient-to-b rounded-lg flex items-center justify-center mr-2 overflow-hidden">
          <img 
            src={customerData.cCompany_LogoFileName 
              ? `https://api.antqtech.com/stanec/photo/CompanyLogo/${customerData.cCompany_LogoFileName}`
              : "/images/default-company-logo.png"}
            alt="Company Logo"
            className="w-full h-full object-cover"
          />
        </div>
          <div>
            <p className="text-sm text-[#808080]">{industryName}</p>
            <p className="text-xl text-[#222222]">{customerData.cCompany_Name}</p>
            <p className="text-lg text-[#398378]">{customerData.cJob_Title}</p>
          </div>
        </div>

        <div className="space-y-3">
          <ContactItem Icon={Mail} text={customerData.cEmail || 'N/A'} />
          <ContactItem Icon={Phone} text={customerData.cTel || 'N/A'} />
          <ContactItem Icon={Phone} text={customerData.cMobile || 'N/A'} />
          <ContactItem Icon={MapPin} text={`${customerData.cAddress} (查看地圖)` || 'N/A'} />
        </div>
      </div>
      <CustomerAttributeModal 
        isOpen={showAttributeModal}
        onClose={() => setShowAttributeModal(false)}
        onSave={(attribute) => setCustomerAttribute(attribute)}
        initialAttribute={customerAttribute}
        ucid={customerData.ucid} // 確保這裡傳入正確的 ucid
      />
    </div>
  );
};

const ContactItem = ({ Icon, text }) => (
  <div className="flex items-center">
    <Icon className="text-[#AAB979] w-6 h-6 mr-3" />
    <span className="text-lg text-[#222222]">{text}</span>
  </div>
);

export default CustomerCardDetail;