import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#808080" fontSize={14} fontFamily="'Noto Sans TC', sans-serif">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5} textAnchor="end" fill="#808080" fontSize={14} fontFamily="'Noto Sans TC', sans-serif">
        {payload.value}
      </text>
    </g>
  );
};

const ExchangeTrendChart = ({ collectedData, beingCollectedData }) => {
  const mergedData = collectedData.map((item, index) => ({
    date: item.date,
    collected: item.count,
    beingCollected: beingCollectedData[index].count
  }));

  return (
    <div className="w-[335px] h-[192px] relative overflow-visible" style={{ marginLeft: '-25px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={mergedData}
          margin={{ top: 12, right: 0, left: 20, bottom: 30 }}
        >
          <CartesianGrid 
            horizontal={true} 
            vertical={false} 
            strokeDasharray="3 3"
            stroke="#F2F2F2"
          />
          <XAxis 
            dataKey="date" 
            axisLine={{ stroke: '#B3B3B3', strokeWidth: 1 }}
            tick={<CustomizedAxisTick />}
            tickLine={false}
          />
          <YAxis 
            axisLine={false}
            tickLine={false}
            tick={<CustomizedYAxisTick />}
            domain={[0, 3]}
            ticks={[0, 1, 2, 3]}
          />
          <Line 
            type="linear" 
            dataKey="collected" 
            stroke="#AAB979" 
            strokeWidth={2}
            dot={false}
            connectNulls
          />
          <Line 
            type="linear" 
            dataKey="beingCollected" 
            stroke="#D4A373" 
            strokeWidth={2}
            dot={false}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ExchangeTrendChart;