import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowRight, MoreVertical } from 'lucide-react';
import CardForm from './CardForm';
// import { getCardById, updateCard } from '../api/cardApi';
import { getCardById, updateCard } from '../api/cardApi';
import { useAuth } from '../context/AuthContext';

const EditCard = () => {
  const [cardData, setCardData] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [showSavedModal, setShowSavedModal] = useState(false);
  const navigate = useNavigate();
  const { cardId } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const data = await getCardById(cardId);
        setCardData(data);
      } catch (error) {
        console.error('獲取名片數據失敗:', error);
        alert('獲取名片數據失敗');
      }
    };

    fetchCardData();
  }, [cardId]);

  const handleUpdate = async (formData) => {
    try {
      const result = await updateCard({ ...formData, cid: cardId });
      if (result.success) {
        setShowSavedModal(true);
        setTimeout(() => {
          setShowSavedModal(false);
          navigate(`/${user.userName}/`);
        }, 3000);
      } else {
        alert('更新失敗: ' + result.message);
      }
    } catch (error) {
      console.error('更新名片時出錯:', error);
      alert('更新名片時出錯: ' + error.message);
    }
  };

  if (!cardData) {
    return <div className="flex items-center justify-center h-screen">載入中...</div>;
  }

  return (
    <div className="bg-white min-h-screen pb-10">
      {showSettings && <CardSettings onClose={() => setShowSettings(false)} />}
      {showSavedModal && (
        <SavedModal
          onClose={() => setShowSavedModal(false)}
          onContinueEdit={() => setShowSavedModal(false)}
          onViewCard={() => navigate(`/${user.userName}/`)}
        />
      )}
      
      <div className="fixed top-0 left-0 right-0 z-10 bg-white">
        <div className="flex flex-col justify-center items-center px-5 py-2.5 gap-1 h-[82px] border-b border-[#E6E6E6]">
          <div className="flex items-center justify-between w-full h-10">
            <button className="w-[30px] h-[30px] text-[#AAB979] transform rotate-180" onClick={() => navigate(-1)}>
              <ArrowRight size={24} />
            </button>
            <h2 className="text-xl font-semibold text-[#222222] flex-grow text-center">編輯名片</h2>
            <div className="flex space-x-2">
              <button className="px-4 py-2 bg-white text-[#398378] border border-[#398378] rounded-full text-base">
                預覽
              </button>
              <button 
                className="p-2 bg-white bg-opacity-80 rounded-full"
                onClick={() => setShowSettings(true)}
              >
                <MoreVertical size={24} className="text-[#4D4D4D]" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-[82px] px-5">
        <CardForm
          initialData={cardData}
          onSubmit={handleUpdate}
          buttonText="更新"
        />
      </div>
    </div>
  );
};

const CardSettings = ({ onClose }) => {
  // 實現設置選單的內容，與 CreateCard 中的相同
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-end justify-center">
      {/* 設置選單的內容 */}
    </div>
  );
};

const SavedModal = ({ onClose, onContinueEdit, onViewCard }) => {
  // 與 CreateCard 中的相同
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold text-[#398378] mb-4">更新成功</h2>
        <div className="flex justify-between">
          <button onClick={onContinueEdit} className="px-4 py-2 border border-[#398378] text-[#398378] rounded-full">
            繼續編輯
          </button>
          <button onClick={onViewCard} className="px-4 py-2 bg-[#398378] text-white rounded-full">
            查看名片
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCard;