import React, { useState, useEffect } from 'react';
import { Search, Plus, X, List, ChevronDown, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { fetchAllContacts } from '../api/contactApi';
import { useAuth } from '../context/AuthContext';

const RecentContact = ({ contact, onCardClick }) => (
  <div 
    className="flex flex-col items-center justify-center w-[60px] h-[82px] gap-1 cursor-pointer"
    onClick={() => onCardClick(contact.cid)}
  >
    <div className="w-[60px] h-[60px] rounded-full overflow-hidden bg-gradient-to-br from-[#D1F1F3] to-[#95DC8A] border-2 border-white">
      <img src={`https://api.antqtech.com/stanec/photo/profilePhoto/${contact.ppFileName}`} alt={contact.cName} className="w-full h-full object-cover" />
    </div>
    <span className="w-full text-xs text-center text-[#808080] truncate">{contact.cName}</span>
  </div>
);

const ContactCard = ({ contact, onCardClick }) => (
  <div 
    className="flex items-center p-[12px_20px] gap-[10px] w-[375px] h-[84px] box-border border-b border-white cursor-pointer"
    onClick={() => onCardClick(contact.cid)}
  >
    <div className="w-[60px] h-[60px] rounded-full overflow-hidden bg-gradient-to-br from-[#D1F1F3] to-[#95DC8A] border-2 border-white flex-shrink-0">
      <img src={`https://api.antqtech.com/stanec/photo/profilePhoto/${contact.ppFileName}`} alt={contact.cName} className="w-full h-full object-cover" />
    </div>
    <div className="flex flex-col justify-center items-start flex-grow gap-1">
      <h3 className="w-full font-['Noto_Sans_TC'] text-base font-normal leading-[150%] tracking-[0.02em] text-[#222222]">
        {contact.cName}
      </h3>
      <p className="w-full font-['Noto_Sans_TC'] text-sm font-normal leading-[150%] tracking-[0.02em] text-[#AAB979]">
        {contact.cCompany_Name} · {contact.cJob_Title}
      </p>
    </div>
    <div className="flex flex-col items-end self-stretch">
      <span className="font-['Noto_Sans_TC'] text-xs font-normal leading-[150%] tracking-[0.02em] text-[#B3B3B3]">
        {new Date(contact.last_Interaction).toLocaleString()}
      </span>
    </div>
  </div>
);

const CategorySection = ({ title, contacts, isExpanded, onToggle, showAddButton, onCardClick }) => (
  <div className="mb-6">
    <div 
      className="flex justify-between items-center p-[12px_20px] gap-[10px] w-[375px] h-[45px] cursor-pointer"
      onClick={onToggle}
    >
      <div className="flex items-center">
        {isExpanded ? <ChevronDown size={21} className="text-[#AAB979]" /> : <ChevronRight size={21} className="text-[#AAB979]" />}
        <span className="font-['Noto_Sans_TC'] font-semibold text-sm leading-[150%] tracking-[0.02em] text-[#1D443E] ml-2">{title} ({contacts.length})</span>
      </div>
      {showAddButton}
    </div>
    {isExpanded && (
      <div>
        {contacts.map(contact => (
          <ContactCard key={contact.cid} contact={contact} onCardClick={onCardClick} />
        ))}
      </div>
    )}
  </div>
);

const ContactList = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('industries');
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedCategories, setExpandedCategories] = useState({});
  const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadContacts = async () => {
      try {
        setLoading(true);
        const data = await fetchAllContacts();
        console.log(data); // 保留這個 console.log 用於調試
        setContacts(data);
      } catch (err) {
        setError('Failed to fetch contacts');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
  
    loadContacts();
  }, []);
  
  useEffect(() => {
    setShowNewCategoryInput(false);
  }, []);

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const toggleNewCategoryInput = () => {
    setShowNewCategoryInput(!showNewCategoryInput);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setShowNewCategoryInput(false);
  };

  const handleCardClick = (contactId) => {
    navigate(`/${user.userName}/contact/${contactId}`);
  };

  const recentContacts = contacts
    .sort((a, b) => new Date(b.last_Interaction) - new Date(a.last_Interaction))
    .slice(0, 5);

  const organizeContacts = () => {
    const organized = {
      industries: {},
      clientTypes: {}
    };

    contacts.forEach(contact => {
      // 按行業組織
      const industry = contact.indName || '未分類';
      if (!organized.industries[industry]) {
        organized.industries[industry] = [];
      }
      organized.industries[industry].push(contact);

      // 按客戶屬性組織
      const clientType = contact.rName || '未分類';
      if (!organized.clientTypes[clientType]) {
        organized.clientTypes[clientType] = [];
      }
      organized.clientTypes[clientType].push(contact);
    });

    return organized;
  };

  const organizedContacts = organizeContacts();

  const filteredContacts = activeTab === 'industries' 
    ? organizedContacts.industries 
    : organizedContacts.clientTypes;

  const totalContacts = contacts.length;

  if (loading) {
    return <div className="flex items-center justify-center h-full">Loading...</div>;
  }

  if (error) {
    return <div className="flex items-center justify-center h-full text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col h-full bg-white">
      {/* 最近聯絡區域 */}
      <div className="px-5 pt-5 pb-[20px] mt-14">
        <h2 className="text-base font-semibold text-[#222222] mb-5">最近聯絡</h2>
        <div className="flex space-x-5 overflow-x-auto">
          {recentContacts.map(contact => (
            <RecentContact key={contact.cid} contact={contact} onCardClick={handleCardClick} />
          ))}
        </div>
      </div>

      {/* 分類標籤欄 */}
      <div className="box-border flex flex-row items-start px-5 pt-0 pb-0 gap-5 w-full h-[54px] border-b border-[#ffffff]">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <button
              className={`flex flex-row justify-center items-center pb-[10px] w-[65px] h-[34px] font-['Noto_Sans_TC'] font-normal text-sm leading-[150%] tracking-[0.02em] ${
                activeTab === 'industries'
                  ? 'border-b-2 border-[#398378] text-[#222222]'
                  : 'text-[#808080]'
              }`}
              onClick={() => handleTabChange('industries')}
            >
              行業類別
            </button>
            <button
              className={`flex flex-row justify-center items-center pb-[10px] w-[65px] h-[34px] ml-[10px] font-['Noto_Sans_TC'] font-normal text-sm leading-[150%] tracking-[0.02em] ${
                activeTab === 'clientTypes'
                  ? 'border-b-2 border-[#398378] text-[#222222]'
                  : 'text-[#808080]'
              }`}
              onClick={() => handleTabChange('clientTypes')}
            >
              客戶屬性
            </button>
          </div>
          <div className="flex flex-row justify-end items-center pb-[10px] h-[34px]">
            <span className="font-['Noto_Sans_TC'] font-normal text-sm leading-[150%] tracking-[0.02em] text-[#BB7A3A]">
              已收藏 {totalContacts} 張
            </span>
          </div>
        </div>
      </div>

      {/* 搜索欄和聯絡人列表 */}
      <div className="flex-1 overflow-hidden flex flex-col">
        {/* 搜索欄 */}
        <div className="flex flex-row items-start px-5 pb-3 gap-2.5 w-full h-14">
          <div className="flex flex-row items-center flex-grow bg-[#F5FAEB] rounded-full p-2.5">
            <Search className="w-6 h-6 text-[#AAB979]" />
            <input
              type="text"
              placeholder="搜尋"
              className="ml-2.5 bg-transparent w-full text-base font-['Noto_Sans_TC'] font-normal leading-[150%] tracking-[0.02em] text-[#CCD5AE] placeholder-[#CCD5AE] focus:outline-none"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          {activeTab === 'clientTypes' && (
            <>
              <button 
                className="flex justify-center items-center w-11 h-11 bg-white border border-[#AAB979] rounded-full"
                onClick={toggleNewCategoryInput}
              >
                {showNewCategoryInput ? (
                  <X className="w-6 h-6 text-[#AAB979]" />
                ) : (
                  <Plus className="w-6 h-6 text-[#AAB979]" />
                )}
              </button>
              <button className="flex justify-center items-center w-11 h-11 bg-white border border-[#AAB979] rounded-full">
                <List className="w-6 h-6 text-[#AAB979]" />
              </button>
            </>
          )}
          {activeTab === 'industries' && (
            <button className="flex justify-center items-center w-11 h-11 bg-white border border-[#AAB979] rounded-full">
              <List className="w-6 h-6 text-[#AAB979]" />
            </button>
          )}
        </div>
        {/* 新分類輸入框 */}
        {showNewCategoryInput && (
          <div className="flex flex-row items-center px-5 mb-3 w-full">
            <div className="flex flex-row items-center p-2.5 w-full bg-white shadow-[0px_4px_12px_rgba(0,0,0,0.15)] rounded-full">
              <input
                type="text"
                placeholder="新分類"
                className="flex-grow font-['Noto_Sans_TC'] text-base leading-[150%] tracking-[0.02em] text-[#CCD5AE] placeholder-[#CCD5AE] focus:outline-none"
              />
              <button className="ml-2 font-['Noto_Sans_TC'] text-base leading-[150%] tracking-[0.02em] text-[#398378]">
                建立
              </button>
            </div>
          </div>
        )}
        <div className="flex-1 overflow-y-auto pb-20">
          {Object.entries(filteredContacts).map(([category, categoryContacts]) => (
            <CategorySection 
              key={category} 
              title={category} 
              contacts={categoryContacts} 
              isExpanded={expandedCategories[category]}
              onToggle={() => toggleCategory(category)}
              showAddButton={activeTab === 'clientTypes'}
              onCardClick={handleCardClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactList;