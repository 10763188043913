import React, { useState } from 'react';
import { Search, ArrowLeft } from 'lucide-react';
import { searchCardById } from '../api/contactApi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const SearchCardId = ({ onClose, onCloseCollectModal }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;

    setIsSearching(true);
    setError(null);
    setSearchResult(null);

    try {
      const result = await searchCardById(searchQuery);
      setSearchResult(result);
    } catch (err) {
      setError('搜尋失敗，請稍後再試');
    } finally {
      setIsSearching(false);
    }
  };

  const handleCardClick = () => {
    if (searchResult && user) {
      navigate(`/${user.userName}/${searchResult.cid}`);
      if (typeof onClose === 'function') {
        onClose();
      }
      if (typeof onCloseCollectModal === 'function') {
        onCloseCollectModal();
      } else {
        console.warn('onCloseCollectModal is not a function');
      }
    }
  };

  return (
    <>
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
      ></div>
      <div className="fixed inset-x-0 bottom-0 z-50 w-full max-w-[375px] h-[600px] mx-auto bg-white rounded-t-3xl shadow-lg">
        <div className="flex flex-col h-[530px]">
          {/* Header */}
          <div className="flex justify-between items-center p-4 border-b border-gray-200">
            <button onClick={onClose} className="text-gray-500">
              <ArrowLeft size={24} />
            </button>
            <h2 className="text-lg font-semibold">搜尋名片ID</h2>
            <div className="w-6"></div>
          </div>

          {/* Search Input */}
          <div className="p-5">
            <div className="flex items-center w-full p-2 bg-[#F5FAEB] rounded-full">
              <Search className="text-[#AAB979] ml-2 mr-3" size={24} />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="輸入名片ID"
                className="flex-grow bg-transparent text-[#222222] placeholder-[#CCD5AE] focus:outline-none"
              />
              <button
                onClick={handleSearch}
                className="text-[#398378] font-normal text-base mr-3"
              >
                搜尋
              </button>
            </div>
          </div>

          {/* Search Results */}
          <div className="flex-grow p-5 overflow-y-auto">
            {isSearching && <p className="text-center">搜尋中...</p>}
            {error && <p className="text-red-500 text-center">{error}</p>}
            {searchResult && (
              <div 
                className="flex items-center p-[12px_20px] gap-[10px] w-full h-[84px] box-border border-b border-white cursor-pointer"
                onClick={handleCardClick}
              >
                <div className="w-[60px] h-[60px] rounded-full overflow-hidden bg-gradient-to-br from-[#D1F1F3] to-[#95DC8A] border-2 border-white flex-shrink-0">
                  <img 
                    src={`https://api.antqtech.com/stanec/photo/profilePhoto/${searchResult.ppFileName}`} 
                    alt={searchResult.cName} 
                    className="w-full h-full object-cover" 
                  />
                </div>
                <div className="flex flex-col justify-center items-start flex-grow gap-1">
                  <h3 className="w-full font-['Noto_Sans_TC'] text-base font-normal leading-[150%] tracking-[0.02em] text-[#222222]">
                    {searchResult.cName}
                  </h3>
                  <p className="w-full font-['Noto_Sans_TC'] text-sm font-normal leading-[150%] tracking-[0.02em] text-[#AAB979]">
                    {searchResult.cCompany_Name} · {searchResult.cJob_Title}
                  </p>
                </div>
              </div>
            )}
            {!searchResult && searchQuery && !isSearching && (
              <p className="text-center"></p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCardId;