import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('jwtToken');
      if (token) {
        try {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
          if (userInfo) {
            setUser({ loggedIn: true, ...userInfo });
          }
        } catch (error) {
          console.error('Failed to initialize auth:', error);
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('userInfo');
          delete axios.defaults.headers.common['Authorization'];
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post('https://api.antqtech.com/Stanec/Account/Login', {
        email,
        uPassword: password
      });

      const data = response.data[0];
      if (data.state === 'OK') {
        const token = data.msg;
        const userId = data.uid;
        const userName = data.uName;
        const userAuth = data.uAuth;

        localStorage.setItem('jwtToken', token);
        localStorage.setItem('userInfo', JSON.stringify({ userId, userName, userAuth, email }));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        setUser({
          loggedIn: true,
          userId,
          userName,
          userAuth,
          email
        });
        return { success: true, username: userName };
      } else {
        throw new Error(data.state);
      }
    } catch (error) {
      console.error('Login failed:', error);
      return { success: false, error: error.message };
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userInfo');
    delete axios.defaults.headers.common['Authorization'];
  };

  const updateUser = (newUserData) => {
    setUser(prevUser => {
      const updatedUser = { ...prevUser, ...newUserData };
      localStorage.setItem('userInfo', JSON.stringify(updatedUser));
      return updatedUser;
    });
  };

  const isAuthenticated = () => {
    return user && user.loggedIn;
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      logout, 
      updateUser, 
      isAuthenticated,
      loading
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};