import React, { useState, useEffect } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { fetchCustomerAttributes, addCustomerAttribute, deleteCustomerAttribute, updateCardRelation } from '../api/contactApi';

const CustomerAttributeModal = ({ isOpen, onClose, onSave, initialAttribute, ucid}) => {
  const [attributes, setAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState(initialAttribute || '未分類');
  const [newAttribute, setNewAttribute] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddingAttribute, setIsAddingAttribute] = useState(false);

  useEffect(() => {
    const loadAttributes = async () => {
      setIsLoading(true);
      try {
        const fetchedAttributes = await fetchCustomerAttributes();
        setAttributes(fetchedAttributes);
      } catch (err) {
        setError('載入客戶屬性失敗');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      loadAttributes();
    }
  }, [isOpen]);

  const handleSave = async () => {
    try {
      const selectedAttributeObj = attributes.find(attr => attr.rName === selectedAttribute);
      if (!selectedAttributeObj) {
        throw new Error('選擇的屬性無效');
      }
      
      const result = await updateCardRelation([ucid], selectedAttributeObj.rid);
      if (result.success) {
        onSave(selectedAttribute);
        onClose();
      } else {
        setError(result.error || '更新關係失敗');
      }
    } catch (err) {
      setError(err.message || '更新關係時發生錯誤');
      console.error('Error updating card relation:', err);
    }
  };

  const handleAddNewAttribute = async () => {
    if (newAttribute.trim()) {
      setIsAddingAttribute(true);
      try {
        const result = await addCustomerAttribute(newAttribute.trim());
        if (result.success) {
          const updatedAttributes = await fetchCustomerAttributes();
          setAttributes(updatedAttributes);
          setNewAttribute('');
          setSelectedAttribute(newAttribute.trim());
        } else {
          setError(result.error);
        }
      } catch (err) {
        setError('添加屬性失敗');
        console.error(err);
      } finally {
        setIsAddingAttribute(false);
      }
    }
  };

  const handleDeleteAttribute = async (rid) => {
    try {
      const result = await deleteCustomerAttribute(rid);
      if (result.success) {
        const updatedAttributes = await fetchCustomerAttributes();
        setAttributes(updatedAttributes);
        if (selectedAttribute === attributes.find(attr => attr.rid === rid)?.rName) {
          setSelectedAttribute('未分類');
        }
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError('刪除屬性失敗');
      console.error(err);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-end justify-center">
      <div className="bg-white w-full max-w-[375px] h-[600px] rounded-t-xl animate-slide-up">
        <div className="flex items-center justify-between px-6 py-5 border-b border-[#CCD5AE]">
          <h2 className="text-xl font-semibold text-[#222222]">設定客戶屬性</h2>
          <button 
            onClick={handleSave}
            className="text-base font-normal text-[#398378]"
          >
            完成
          </button>
        </div>
        <div className="p-5 space-y-4">
          <div className="flex items-center space-x-2 bg-[#F5FAEB] rounded-full p-2">
            <input
              type="text"
              placeholder="輸入屬性名稱"
              value={newAttribute}
              onChange={(e) => setNewAttribute(e.target.value)}
              className="flex-grow bg-transparent text-[#222222] placeholder-[#CCD5AE] focus:outline-none"
            />
            <button 
              onClick={handleAddNewAttribute}
              className="text-[#398378] flex items-center"
              disabled={isAddingAttribute}
            >
              {isAddingAttribute ? (
                <span>添加中...</span>
              ) : (
                <>
                  <Plus size={18} className="mr-1" />
                  新增
                </>
              )}
            </button>
          </div>
          
          {error && <div className="text-red-500 text-sm">{error}</div>}
          
          {isLoading ? (
            <div className="text-center py-4">載入中...</div>
          ) : (
            attributes.map((attr) => (
              <div key={attr.rid} className="flex items-center p-[12px_20px] gap-[10px] h-[48px] border-b border-white">
                <input
                  type="radio"
                  id={`attr-${attr.rid}`}
                  checked={selectedAttribute === attr.rName}
                  onChange={() => setSelectedAttribute(attr.rName)}
                  className="w-6 h-6 text-[#AAB979]"
                />
                <label htmlFor={`attr-${attr.rid}`} className="flex-grow-0 w-[240px] font-['Noto_Sans_TC'] text-base leading-[150%] tracking-[0.02em] text-[#222222]">
                    {attr.rName}
                </label>
                {attr.rName !== '未分類' && (
                  <button
                    onClick={() => handleDeleteAttribute(attr.rid)}
                    className="w-6 h-6 flex items-center justify-center"
                  >
                    <Trash2 size={24} className="text-[#AAB979] " />
                  </button>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerAttributeModal;