import React from 'react';
import { X } from 'lucide-react';
import QRCode from 'qrcode.react';

const QRCodeModal = ({ onClose, username }) => {
  // 生成共享 URL
  const sharedUrl = `https://stanec.antqtech.com/shared/${username}`;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end justify-center z-50">
      <div className="bg-white w-full max-w-[375px] h-[600px] rounded-t-xl shadow-lg flex flex-col animate-slide-up">
        <div className="flex justify-between items-center p-5 border-b border-[#E6E6E6]">
          <div className="w-[287px] h-[8px]"></div>
          <button onClick={onClose}>
            <X size={24} className="text-[#808080]" />
          </button>
        </div>
        <div className="flex flex-col items-center p-5 gap-3 flex-grow">
          <div className="w-full bg-white flex justify-center items-center p-2.5 mt-5 mb-5">
            <img src="/images/stanec-logo.png" alt="Stanec Logo" className="w-[350px] h-[40px]" />
          </div>
          <div className="w-[250px] h-[250px] mb-5">
            <QRCode value={sharedUrl} size={250} />
          </div>
          <div className="text-[#398378] text-lg">@{username}</div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeModal;