import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EyeOff, Eye } from 'lucide-react';
import axios from 'axios';

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('https://api.antqtech.com/Stanec/Account/VerifyEmail', {
        email: formData.email,
        vType: 1
      });
      if (response.data[0].state === 'OK') {
        navigate('/verify-email', { 
          state: { 
            email: formData.email, 
            verificationToken: response.data[0].msg,
            password: formData.password,
            name: formData.name,
            phoneCode: "tw", // 假設電話區號為 tw，您可能需要根據實際情況調整
            phone: formData.phone
          } 
        });
      } else {
        console.error('Failed to send verification email');
        // 可以在這裡添加錯誤提示
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      // 可以在這裡添加錯誤提示
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-start p-5 pb-10 gap-6 w-full min-h-screen bg-gradient-to-b from-[#E9F3D1] to-white">
      <div className="flex items-center gap-2 mt-5">
        <img src="/images/stanec-logo.png" alt="STANEC" className="w-full" />
      </div>
      
      <div className="w-full">
        <h1 className="text-2xl font-black text-[#398378] font-['Noto_Serif_TC']">註冊會員</h1>
        <div className="flex items-center justify-between mt-2">
          <span className="text-sm text-[#001528]">已經是會員了？</span>
          <Link to="/login" className="flex items-center text-sm text-[#398378]">
            立即登入
            <svg className="w-5 h-5 ml-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </Link>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="w-full space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm text-[#1D443E] mb-2">名稱(請用英文)</label>
          <input
            id="name"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-5 py-2.5 bg-white rounded-full shadow-md text-[#222222] placeholder-[#CCD5AE]"
            placeholder="輸入姓名"
            required
          />
        </div>
        
        <div>
          <label htmlFor="email" className="block text-sm text-[#1D443E] mb-2">Email</label>
          <input
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-5 py-2.5 bg-white rounded-full shadow-md text-[#222222] placeholder-[#CCD5AE]"
            placeholder="輸入Email"
            required
          />
        </div>
        
        <div>
          <label htmlFor="phone" className="block text-sm text-[#1D443E] mb-2">聯絡電話</label>
          <div className="flex gap-1">
            <select className="w-24 px-4 py-2.5 bg-white rounded-full shadow-md text-[#222222]">
              <option>+886</option>
            </select>
            <input
              id="phone"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              className="flex-1 px-5 py-2.5 bg-white rounded-full shadow-md text-[#222222] placeholder-[#CCD5AE]"
              placeholder="聯絡電話"
              required
            />
          </div>
        </div>
        
        <div>
          <label htmlFor="password" className="block text-sm text-[#1D443E] mb-2">密碼</label>
          <div className="relative">
            <input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              className="w-full px-5 py-2.5 bg-white rounded-full shadow-md text-[#222222] placeholder-[#CCD5AE]"
              placeholder="輸入密碼"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              {showPassword ? <EyeOff size={24} className="text-[#AAB979]" /> : <Eye size={24} className="text-[#AAB979]" />}
            </button>
          </div>
          <p className="mt-2 text-sm text-[#AAB979]">密碼需為 8~20 字英文及數字</p>
        </div>
        
        <p className="text-sm text-[#1D443E]">
          註冊即表示您已經閱讀並同意此平台的
          <a href="#" className="text-[#398378]">服務條款</a> 與
          <a href="#" className="text-[#398378]">隱私權政策</a>
        </p>
        
        <button 
          type="submit" 
          className="w-full py-2 bg-[#398378] text-white rounded-full disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? '處理中...' : '註冊會員'}
        </button>
      </form>
      
      {/* <div className="w-full flex items-center">
        <div className="flex-grow h-px bg-[#E6E6E6]"></div>
        <span className="px-4 text-sm text-[#AAB979]">或</span>
        <div className="flex-grow h-px bg-[#E6E6E6]"></div>
      </div>
      
      <button className="w-full py-2 border border-[#398378] text-[#398378] rounded-full flex items-center justify-center">
        <img src="/images/icon/google.png" alt="Google" className="w-6 h-6 mr-2" />
        使用Google帳號註冊
      </button> */}
    </div>
  );
};

export default Register;