import axios from 'axios';

const BASE_URL = 'https://api.antqtech.com/Stanec';

//////////////////////////////////////////
// 轉換用

// 定義社群軟體 ID 映射
const socialMediaIdMap = {
  'facebook': 1,
  'instagram': 2,
  'linkedin': 3,
  'twitter': 4,
  // 添加更多社群軟體映射
};

// 假設我們有一個函數來轉換國家代碼
const convertNationCodeToRegionCode = (code) => {
  const codeMap = {
    '886': 'tw',
    '1': 'us',
    // 添加更多國家代碼映射
  };
  return codeMap[code] || code;
};


/////////////////////////////////////////////

// 添加響應攔截器
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // 清除本地存儲的 token
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('userInfo');
      // 重定向到登錄頁面
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const deleteCard = async (cid) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/DelCard`,
      { cid },
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return { success: true, message: '卡片刪除成功' };
    } else {
      throw new Error(response.data[0].msg || '刪除卡片失敗');
    }
  } catch (error) {
    console.error('刪除卡片時出錯:', error);
    return { success: false, message: error.message || '刪除卡片失敗' };
  }
};

// 更新
export const updateCard = async (cardData) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/UpdCard`,
      cardData,
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return { success: true, message: '名片更新成功' };
    } else {
      throw new Error(response.data[0].msg || '更新名片失敗');
    }
  } catch (error) {
    console.error('更新名片時出錯:', error);
    return { success: false, message: error.message || '更新名片失敗' };
  }
};

// 新增
export const addCard = async (formData) => {
  try {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const payload = {
      cid: formData.cardId.replace('@', '').trim(),
      cName: formData.name.trim(),
      cEmail: formData.email?.trim() || "",
      cCompany_Name: formData.company?.trim() || "",
      cCompany_LogoBase64: formData.companyLogo?.base64?.split(',')[1] || "",
      cCompany_Logo: formData.companyLogo?.extension || "",
      cJob_Title: formData.title?.trim() || "",
      indID: parseInt(formData.industry) || 0,
      cAddress: formData.address?.trim() || "",
      linkList: formData.socialLinks.map((link, index) => ({
        link_ID: socialMediaIdMap[link.platform] || 0,
        link_Url: link.url.trim(),
        link_Position: index + 1
      })).filter(link => link.link_Url && link.link_ID),
      cDescription: formData.bio?.trim() || "",
      profilePhotoBase64: formData.avatar?.base64?.split(',')[1] || "",
      profilePhoto: formData.avatar?.extension || "",
      backgroundPhotoBase64: formData.coverPhoto?.base64?.split(',')[1] || "",
      backgroundPhoto: formData.coverPhoto?.extension || "",
    };

    // 處理公司電話
    if (formData.companyPhone?.number) {
      payload.cTel = formData.companyPhone.number.trim();
      payload.tNationID = convertNationCodeToRegionCode(formData.companyPhone.areaCode.replace('+', ''));
    } else {
      payload.cTel = "";
      payload.tNationID = "";
    }
  
    // 處理個人手機
    if (formData.personalPhone?.number) {
      payload.cMobile = formData.personalPhone.number.trim();
      payload.mNationID = convertNationCodeToRegionCode(formData.personalPhone.areaCode.replace('+', ''));
    } else {
      payload.cMobile = "";
      payload.mNationID = "";
    }

    console.log("API 調用前的 payload:", payload);

    // 移除所有未定義的值
    Object.keys(payload).forEach(key => {
      if (payload[key] === undefined) {
        delete payload[key];
      }
    });

    console.log("API 調用前的 payload:", payload);

    const response = await axios.post(
      `${BASE_URL}/Card/AddCard`,
      payload,
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );
    console.log("form", payload);
    if (response.data && response.data[0] && response.data[0].state === 'OK') {
      return { success: true, message: '名片保存成功' };
    } else {
      throw new Error(response.data[0]?.msg || '未知錯誤');
    }
  } catch (error) {
    console.error('保存名片時出錯:', error.response?.data || error.message);
    let errorMessage;
    if (error.response) {
      errorMessage = error.response.data?.message || error.response.statusText;
    } else if (error.request) {
      errorMessage = '未收到服務器響應';
    } else {
      errorMessage = error.message;
    }
    return { success: false, message: errorMessage };
  }
};

export const getCardById = async (cid) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${BASE_URL}/Card/SelCard`,
      { cid, ucid: "string" },  // 使用固定的 ucid 值
      {
        headers: {
          'Authorization': token,
          'accept': '*/*',
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data[0].state === "OK") {
      return response.data[0];
    } else {
      throw new Error(response.data[0].msg || '搜尋失敗');
    }
  } catch (error) {
    console.error("搜尋名片失敗:", error);
    throw error;
  }
};