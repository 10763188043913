import React, { useState, useEffect, useRef } from 'react';
import { Share2, QrCode, Edit, MoreVertical, Phone, Mail, Star, Link, Linkedin, Instagram, Facebook, ChevronLeft, ChevronRight } from 'lucide-react';
import QRCodeModal from '../components/QRCodeModal';
import CardDetails from './CardDetails';
import { useCards } from '../context/CardContext';
import { deleteCard } from '../api/cardApi';
import WelcomeCard from './WelcomeCard';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const DigitalCard = () => {
  const { cardData, isLoading, error, isNewUser, refreshCards } = useCards();
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragDistance, setDragDistance] = useState(0);
  const cardContainerRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const MIN_SWIPE_DISTANCE = 100;
  const DRAG_THRESHOLD = 0.5;

  useEffect(() => {
    refreshCards();
  }, []);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !e.target.closest('.dropdown-container')) {
        setShowDropdown(false);
      }
    };
  
    document.addEventListener('click', closeDropdown);
  
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (cardData.cards && cardData.cards.length > 0) {
      setCurrentCardIndex(0);
    }
  }, [cardData]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
    setIsDragging(true);
    setDragDistance(0);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    if (isDragging) {
      const currentDragDistance = touchStart - e.targetTouches[0].clientX;
      setDragDistance(currentDragDistance * DRAG_THRESHOLD);
    }
  };

  const handleEditClick = (cardId) => {
    navigate(`/${user.userName}/edit-card/${cardId}`);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
    const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;
    if (isLeftSwipe && currentCardIndex < cardData.cards.length - 1) {
      setCurrentCardIndex(prev => prev + 1);
    }
    if (isRightSwipe && currentCardIndex > 0) {
      setCurrentCardIndex(prev => prev - 1);
    }
    setIsDragging(false);
    setDragDistance(0);
  };

  // 刪除的
  const handleDeleteCard = async () => {
    if (window.confirm('確定要刪除這張卡片嗎？')) {
      const result = await deleteCard(cardData.cards[currentCardIndex].cid);
      if (result.success) {
        refreshCards();
        setShowDropdown(false);
        // 可以在這裡添加一個成功的提示
      } else {
        // 顯示錯誤消息
        alert(result.message);
      }
    }
  };

  const transformCardData = (apiCard) => ({
    id: apiCard.cid,
    username: apiCard.cid,
    name: apiCard.cName,
    likes: apiCard.cSave_Count,
    company: apiCard.cCompany_Name,
    title: apiCard.cJob_Title,
    bio: apiCard.cDescription,
    email: apiCard.cEmail,
    phone: apiCard.cMobile,
    address: apiCard.cAddress,
    socialLinks: apiCard.linkList ? apiCard.linkList.map(link => ({
      icon: link.link_Url.includes('instagram') ? Instagram : 
            link.link_Url.includes('linkedin') ? Linkedin : 
            link.link_Url.includes('facebook') ? Facebook : Link,
      url: link.link_Url
    })) : [],
    avatar: apiCard.ppFileName ? `https://api.antqtech.com/stanec/photo/profilePhoto/${apiCard.ppFileName}` : '/images/default-avatar.png',
    companyLogo: apiCard.cCompany_LogoFileName ? `https://api.antqtech.com/stanec/photo/CompanyLogo/${apiCard.cCompany_LogoFileName}` : null,
    backgroundImage: apiCard.bpFileName ? `https://api.antqtech.com/stanec/photo/BackgroundPhoto/${apiCard.bpFileName}` : null    
  });

  const renderCard = (card, index) => (
    <div 
      key={card.id} 
      className="w-full h-full bg-white rounded-lg shadow-2xl overflow-hidden absolute top-0 left-0 transition-transform duration-300 ease-out flex flex-col"
      style={{
        transform: `translateX(${(index - currentCardIndex) * 100 - (isDragging ? dragDistance / cardContainerRef.current?.clientWidth * 100 : 0)}%)`,
      }}
    >
      <div className="relative h-[120px] bg-gradient-to-r from-blue-400 to-blue-600 flex flex-row items-end p-5 gap-2.5"
           style={card.backgroundImage ? {backgroundImage: `url(${card.backgroundImage})`, backgroundSize: 'cover'} : {}}>
        <div className="absolute top-1/2 left-5 transform -translate-y-1/2 flex items-center">
          <div className="w-20 h-20 rounded-full bg-gradient-to-br from-[#E9F3D1] to-[#72A36A] border-2 border-white shadow-lg overflow-hidden flex items-center justify-center">
            <img src={card.avatar} alt="Profile" className="w-full h-full object-cover" />
          </div>
          <div className="ml-0 mt-12 px-2 py-1 rounded-full flex items-center">
            <Star size={16} className="text-[#ffffff] mr-1" />
            <span className="text-sm font-semibold text-[#ffffff]">
              {card.likes}
            </span>
          </div>
        </div>
        
        <div className="flex flex-row justify-end items-start gap-2.5 flex-grow h-20">
          {/* 帶編輯 */}
          {/* <button 
          onClick={() => handleEditClick(card.id)} 
          className="w-10 h-10 bg-white bg-opacity-80 rounded-full flex items-center justify-center">
            <Edit size={24} className="text-[#4D4D4D]" />
          </button> */}
          <div className="relative dropdown-container">
            <button 
              className="w-10 h-10 bg-white bg-opacity-80 rounded-full flex items-center justify-center"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <MoreVertical size={24} className="text-[#4D4D4D]" />
            </button>
            {showDropdown && <DropdownMenu onClose={() => setShowDropdown(false)} />}
          </div>
        </div>
      </div>
      
      <div className="flex flex-col items-start p-5 flex-grow">
        <div className="flex flex-row items-center gap-1 mb-1 w-full">
          {card.companyLogo ? (
            <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
              <img src={card.companyLogo} alt="Company Logo" className="w-full h-full object-cover" />
            </div>
          ) : (
            <div className="w-[30px] h-[30px] bg-gradient-to-b from-[#6390E7] to-[#0053F4] rounded-full flex items-center justify-center text-white text-xs">
              ST
            </div>
          )}
          <h2 className="text-xl font-black text-[#222222] font-['Noto_Serif_TC'] tracking-[0.02em] flex-grow">{card.name}</h2>
          <button 
            onClick={() => setShowDetails(true)}
            className="w-[30px] h-[30px] flex items-center justify-center"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#AAB979" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        <p className="text-lg text-[#4D4D4D] mb-1 w-full">{card.company} · {card.title}</p>
        <p className="text-sm text-[#808080] mb-2 w-full">{card.address}</p>
        <div className="flex items-start gap-1 mb-3 w-full">
          <span className="text-[#E9F3D1] text-lg">"</span>
          <p className="text-sm text-[#CCCCCC] line-clamp-2 flex-grow">{card.bio}</p>
        </div>
        
        <div className="space-y-2 mb-3 w-full">
          <div className="flex items-center text-base text-[#222222]">
            <Phone size={24} className="mr-2.5 text-[#AAB979]" />
            <span>{card.phone || 'N/A'}</span>
          </div>
          <div className="flex items-center text-base text-[#222222]">
            <Mail size={24} className="mr-2.5 text-[#AAB979]" />
            <span>{card.email}</span>
          </div>
        </div>
        
        <div className="my-3 border-t border-[#E6E6E6] w-full"></div>
        
        <div className="flex justify-start space-x-4">
          {card.socialLinks.map((link, i) => (
            <a key={i} href={link.url} className="w-9 h-9 flex items-center justify-center rounded-full bg-[#398378] text-white">
              <link.icon size={20} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );

  const DropdownMenu = ({ onClose }) => (
    <div className="absolute top-full right-0 mt-2 w-[200px] bg-[#F2F2F2] rounded-[12px] shadow-lg z-20">
      <div className="flex flex-col p-2">
        {/* <MenuItem text="複製" />
        <MenuItem text="隱藏" />
        <MenuItem text="調整順序" />
        <MenuItem text="公開被加入次數" /> */}
        <div className="h-4" />
        <MenuItem 
          text="刪除" 
          textColor="text-[#F65757]" 
          onClick={handleDeleteCard}
        />
      </div>
    </div>
  );
  
  const MenuItem = ({ text, textColor = "text-[#222222]", onClick }) => (
    <button 
      className={`flex items-center px-5 py-2 w-full hover:bg-white ${textColor} text-left`}
      onClick={onClick}
    >
      {text}
    </button>
  );
  
  const handleAddCard = () => {
    if (user?.userName) {
      navigate(`/${user.userName}/create-card`);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-lg text-gray-600">載入中...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-lg text-red-500">錯誤：{error}</p>
      </div>
    );
  }

  if (isNewUser || !cardData.cards || cardData.cards.length === 0) {
    return <WelcomeCard />;
  }

  // console.log('Rendering normal DigitalCard');

  return (
    <div className="h-full py-5 px-5 bg-[#FFFFFF] flex flex-col">
      <div className="flex justify-between items-center mb-4 h-10 mt-14">
        <div className="flex items-center justify-center px-4 py-2 bg-[#F5FAEB] rounded-full">
          <span className="text-base text-[#398378]">@{cardData.cards[currentCardIndex].cid}</span>
        </div>
        
        <div className="flex space-x-2">
          {/* 帶編輯 */}
          {/* <button className="w-10 h-10 flex items-center justify-center bg-[#F5FAEB] rounded-full">
            <Share2 size={24} className="text-[#398378]" />
          </button> */}
          <button 
            className="w-10 h-10 flex items-center justify-center bg-[#F5FAEB] rounded-full"
            onClick={() => setShowQRCode(true)}
          >
            <QrCode size={24} className="text-[#398378]" />
          </button>
        </div>
      </div>

      <div 
        ref={cardContainerRef}
        className="flex-grow mb-3 relative overflow-hidden"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {cardData.cards.map((card, index) => renderCard(transformCardData(card), index))}
      </div>
      
      <div className="flex justify-center items-center space-x-10 h-[30px]">
        <button 
          className="w-[30px] h-[30px] flex items-center justify-center"
          onClick={() => currentCardIndex > 0 && setCurrentCardIndex(prev => prev - 1)}
          disabled={currentCardIndex === 0}
        >
          <ChevronLeft size={30} className="text-[#AAB979]" />
        </button>
        <span className="text-xl font-bold text-[#398378]">{currentCardIndex + 1} / {cardData.cards.length}</span>
        <button 
          className="w-[30px] h-[30px] flex items-center justify-center"
          onClick={() => currentCardIndex < cardData.cards.length - 1 && setCurrentCardIndex(prev => prev + 1)}
          disabled={currentCardIndex === cardData.cards.length - 1}
        >
          <ChevronRight size={30} className="text-[#AAB979]" />
        </button>
      </div>

      {showQRCode && (
        <QRCodeModal 
          onClose={() => setShowQRCode(false)} 
          username={cardData.cards[currentCardIndex].cid} 
        />
      )}
      {showDetails && (
        <CardDetails 
        onClose={() => setShowDetails(false)}
        cid={cardData.cards[currentCardIndex].cid}
      />
      )}
    </div>
  );
};

export default DigitalCard;