import React, { useState } from 'react';
import { Search, QrCode, CreditCard, Edit } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import SearchCardId from './SearchCardId'; // 確保路徑正確

const CollectCardModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [showSearchCard, setShowSearchCard] = useState(false);

  if (!isOpen) return null;

  const handleScanQRCode = () => {
    onClose();
    navigate('/scan');
  };

  const handleSearchCardId = () => {
    setShowSearchCard(true);
  };

  const handleCloseSearch = () => {
    setShowSearchCard(false);
  };

  if (showSearchCard) {
    return (
      <SearchCardId 
        onClose={handleCloseSearch} 
        onCloseCollectModal={onClose}
      />
    );
  }

  return (
    <>
      {/* 背景遮罩 */}
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
      ></div>
      
      {/* 滑动页面 */}
      <div className="fixed inset-x-0 bottom-0 h-[600px] z-50 w-full max-w-[375px] mx-auto animate-slide-up">
        <div className="bg-white rounded-t-3xl shadow-lg">
          <div className="flex justify-between items-center p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold">收藏名片</h2>
            <button onClick={onClose} className="text-gray-500">
              X
            </button>
          </div>
          <div className="p-5 space-y-4 pb-[80%]">
            <p className="font-semibold text-sm text-[#1D443E]">對方已註冊 Stanec 電子名片</p>
            <div 
              className="flex items-center space-x-3 p-3 border-b border-white cursor-pointer"
              onClick={handleSearchCardId}
            >
              <Search className="text-[#398378]" size={24} />
              <span className="text-[#222222]">搜尋名片ID</span>
            </div>
            <div 
              className="flex items-center space-x-3 p-3 border-b border-white cursor-pointer"
              onClick={handleScanQRCode}
            >
              <QrCode className="text-[#398378]" size={24} />
              <span className="text-[#222222]">掃描 QR Code 收藏</span>
            </div>
            <p className="font-semibold text-sm text-[#1D443E] mt-6">只有實體名片嗎？</p>
            <div className="flex items-center space-x-3 p-3 border-b border-white">
              <CreditCard className="text-[#398378]" size={24} />
              <span className="text-[#222222]">掃描編輯實體名片</span>
            </div>
            <div className="flex items-center space-x-3 p-3 border-b border-white">
              <Edit className="text-[#398378]" size={24} />
              <span className="text-[#222222]">手動編輯實體名片</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectCardModal;