export const industryCodeMap = {
    1: '農、林、漁、牧業',
    2: '礦業及土石採取業',
    3: '製造業',
    4: '電力及燃氣供應業',
    5: '用水供應及污染整治業',
    6: '營造業',
    7: '批發及零售業',
    8: '運輸及倉儲業',
    9: '住宿及餐飲業',
    10: '資訊及通訊傳播業',
    11: '金融及保險業',
    12: '不動產業',
    13: '專業、科學及技術服務業',
    14: '支援服務業',
    15: '公共行政及國防；強制性社會安全',
    16: '教育服務業',
    17: '醫療保健及社會工作服務業',
    18: '藝術、娛樂及休閒服務業',
    19: '其他服務業'
  };