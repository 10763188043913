import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import { CardProvider } from './context/CardContext';
import Header from './components/Header';
import BottomNavigation from './components/BottomNavigation';
import IntroPage from './pages/IntroPage';
import Login from './pages/Login';
import Register from './pages/Register';
import EditCard from './components/EditCard';
import DigitalCard from './pages/DigitalCard';
import ContactList from './pages/ContactList';
import DataAnalysis from './pages/DataAnalysis';
import ScanCard from './pages/ScanCard';
import Settings from './pages/Settings';
import CreateCard from './components/CreateCard';
import CustomerCardDetail from './pages/CustomerCardDetail';
import WelcomeCard from './pages/WelcomeCard';
import VerifyEmail from './pages/VerifyEmail';
import SharedCardDetail from './pages/SharedCardDetail'; // 新增這行

function ProtectedRoute({ children }) {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function AppRoutes() {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/intro" element={<IntroPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/shared/:shareCode" element={<SharedCardDetail />} /> {/* 新增這行 */}
      <Route path="/:username/*" element={
        <ProtectedRoute>
          <div className="flex flex-col h-screen bg-gray-100">
            <Header />
            <main className="flex-1 overflow-y-auto pb-16">
              <Routes>
                <Route path="/" element={<DigitalCard />} />
                <Route path="/create-card" element={<CreateCard />} />
                <Route path="/contact/:id" element={<CustomerCardDetail />} />
                <Route path="/:id" element={<CustomerCardDetail />} />
                <Route path="/contacts" element={<ContactList />} />
                <Route path="/scan" element={<ScanCard />} />
                <Route path="/analysis" element={<DataAnalysis />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/edit-card/:cardId" element={<EditCard />} />
              </Routes>
            </main>
            <BottomNavigation />
          </div>
        </ProtectedRoute>
      } />
      
      <Route path="/" element={
        user ? <Navigate to={`/${user.userName}`} replace /> : <Navigate to="/intro" replace />
      } />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider>
      <CardProvider>
        <Router>
          <AppRoutes />
        </Router>
      </CardProvider>
    </AuthProvider>
  );
}

export default App;